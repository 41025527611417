@media screen and (max-width: 1200px) {
    .ctn-qt-hm {
        position: relative;
        display: flex;
        flex-direction: row;
        min-height: 100vh;
        .choix-txt {
        }
        h1 {
            left: 96px;
            top: 56px;
        }
        .order-qt {
            left: 20px;
            top: 56px;
        }
        .ctn-ct-qt {
            padding: 180px 26px 150px 26px;
            h2 {
            }
        }
        .ctn-jk {
            width: 36%;
            padding: 180px 26px 0 26px;
        }
    }
    .body-rk {
        width: 800px;
    }
}

// Additional Adjustment
@media (max-width: 1200px) and (min-width: 901px) {
    .body-pf {
        width: 900px;
        .row-pf {
            .pf-sc {
                margin: 0 auto;
            }
            .pf-pro {
                margin: unset;
            }
            .pf-res {
                margin: unset;
            }
        }
    }
    .pf-sc-by-lv {
        max-width: 290px;
    }
}
