@media screen and (max-width: 750px) {
    .lg-mask-top.hide,
    .lg-mask-bot.hide {
        height: 56px;
    }
    .ctn-ct-lv {
        flex-direction: column-reverse;
        padding-top: 10px;
        padding-bottom: 10px;
        .ctn-qt-lt {
            width: 100%;
            padding: 25px 26px;
            border-right-width: 0;
        }
        .ctn-bn {
            width: 100%;
            padding-right: 26px;
        }
    }
    .ctn-res {
        .res-ct {
            a {
                width: 50%;
            }
        }
    }
    .ttl-nav {
        display: flex;
        align-items: center;
        color: $m-primary;
        @include default_police(14px, bold, 1.26px, center);
        line-height: 40px;
        text-transform: uppercase;
        padding-left: 20px;
    }
    .pf-sc-by-lv {
        max-width: 343px;
    }
    .ctn-qt-hm {
        flex-direction: column;
        .qt-ctn-bn {
            display: flex;
            flex-direction: column;
            margin-top: 0;
            background-color: $white;
            padding: 10px 1px 30px 1px;
            .ct-bn {
                h2 {
                    padding-top: 0;
                    padding-bottom: 0;
                    color: $m-primary;
                    font-size: 14px;
                    margin: 0;
                }
                p {
                    font-family: "Roboto", serif;
                    font-size: 14px;
                    margin: 0;
                }
            }
        }
        .ctn-jk {
            position: fixed;
            top: 0;
            left: 110vw;
            right: 0;
            bottom: 0;
            width: 100vw;
            height: 100vw;
            z-index: 1050;
            background-color: $transparent;
            -webkit-transition: left 0.3s ease-in-out;
            transition: left 0.3s ease-in-out;
            .jk-box {
                position: absolute;
                right: 0;
                top: 60px;
                width: 304px;
                border-top-left-radius: 15px;
                border-bottom-left-radius: 15px;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                .jk-ttl {
                    justify-content: flex-end;
                    font-size: 16px;
                    text-align: right;
                    border-bottom-width: 0.3px;
                }
                .jk-ct {
                    h2 {
                        text-align: left;
                    }
                }
            }
        }
        .ctn-jk.active {
            left: 0;
        }
    }
    .btn-submit-qt {
        width: 100vw;
        height: 74px;
        position: fixed;
        bottom: -75px;
        left: 0;
        border-bottom-left-radius: 0;
        -webkit-transition: bottom 200ms ease-in-out;
        transition: bottom 200ms ease-in-out;
        border-bottom-right-radius: 0;
    }
    .btn-submit-qt.active {
        bottom: 0;
        -webkit-transition: bottom 200ms ease-in-out;
        transition: bottom 200ms ease-in-out;
    }
    .btn-jk {
        cursor: pointer;
        visibility: visible;
        width: 50px;
        height: 50px;
        top: 60px;
        right: 0;
        background-color: $m-button;
        box-shadow: 0 12px 17px 0 $m-dark-25;
        border-top-left-radius: 50%;
        border-bottom-left-radius: 50%;
        display: flex;
        position: fixed;
        justify-content: center;
        text-align: center;
        z-index: 1030;
        align-items: center;
        img {
            width: 30px;
            height: auto;
        }
    }
    .btn-jk.active {
        display: none;
        visibility: hidden;
    }
    .ctn-qt-hm {
        padding-top: 0;
        .order-qt {
            display: block;
            height: 50px;
            width: 50px;
            white-space: nowrap;
            font-size: 35px;
            clear: both;
            line-height: 50px;
            margin: 0;
            left: calc(50% - 25px);
            z-index: 5;
        }
        h1 {
            text-align: center;
            flex-direction: column;
            left: 0;
            padding-right: 26px;
            top: 0;
            position: relative;
            margin-top: 0;
            background-color: $m-primary;
            color: $white;
            padding-bottom: 20px;
            padding-top: 150px;
            z-index: 0;
            padding-left: 26px;
            height: auto;
        }
        .ctn-ct-qt {
            width: 100%;
            padding-bottom: 80px;
            padding-top: 0;
        }
    }
    .jk-icon-hidde {
        display: block;
        visibility: visible;
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 15px;
        font-size: 25px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 51px;
        letter-spacing: 1.43px;
        text-align: center;
        color: $m-dark-50;
    }
    .body-pf {
        .row-pf {
            .pf-pro {
                .lv-pf-ctn.jj2 {
                    min-height: 240px;
                }
                .lv-pf-ctn.jj3 {
                    min-height: 320px;
                }
                .lv-pf-ctn {
                    width: 342px;
                    margin: auto;
                    margin-top: 35px;
                    .ml0 {
                        top: 0;
                    }
                    .ml1 {
                        top: 92px;
                    }
                    .ml2 {
                        top: 184px;
                    }
                    .ml3 {
                        top: 276px;
                    }
                    .mo0,
                    .mo3,
                    .mo6,
                    .mo9,
                    .mo12,
                    .mo15,
                    .mo18 {
                        left: 25px;
                    }
                    .mo1,
                    .mo4,
                    .mo7,
                    .mo10,
                    .mo13,
                    .mo16,
                    .mo19 {
                        left: 146px;
                    }
                    .mo2,
                    .mo5,
                    .mo8,
                    .mo11,
                    .mo14,
                    .mo17,
                    .mo20 {
                        left: 267px;
                    }
                    .mll0 {
                        top: 0;
                    }
                    .mll1 {
                        top: 92px;
                    }
                    .mll2 {
                        top: 184px;
                    }
                    .mll3 {
                        top: 276px;
                    }
                    .mol0,
                    .mol3,
                    .mol6,
                    .mol9,
                    .mol12,
                    .mol15,
                    .mol18,
                    .mol21 {
                        display: none;
                    }
                    .mol1,
                    .mol4,
                    .mol7,
                    .mol10,
                    .mol13,
                    .mol16,
                    .mol19,
                    .mol22 {
                        left: 75px;
                    }
                    .mol2,
                    .mol5,
                    .mol8,
                    .mol11,
                    .mol14,
                    .mol17,
                    .mol20,
                    .mol23 {
                        left: 197px;
                    }
                    .dol5,
                    .dol10,
                    .dol15 {
                        display: block;
                    }
                    .ln-snd-lv {
                        display: none;
                    }
                    .ln-snd-lv-mb {
                        display: block;
                        position: absolute;
                    }
                    .ln-snd-lv-mb.ll1,
                    .ln-snd-lv.ll1 {
                        top: 25px;
                    }
                    .ln-snd-lv-mb.ll2,
                    .ln-snd-lv.ll2 {
                        top: 117px;
                    }
                }
            }
            .pf-res {
                max-width: 343px;
            }
        }
    }
    .body-rk {
        width: 100%;
        margin-top: 0;
        margin-bottom: 0;
        .row-rk {
            flex-direction: column;
            .col-rk {
                margin: auto;
                padding: 30px 0;
                &:first-of-type {
                    border-bottom: solid 0.5px $m-dark-25;
                }
            }
        }
    }
    .body-st {
        width: 100%;
        flex-direction: column;
        .row-st {
            margin: 20px auto;
            &.row-av-st {
                width: 100%;
                justify-content: center;
                align-items: center;
                flex-direction: row;
                .av-st {
                    flex-grow: 0;
                    flex-shrink: 0;
                    margin: 0;
                    justify-content: space-between;
                    height: 180px;
                    margin: 10px;
                }
                .lng-st {
                    height: 180px;
                    flex-grow: 0;
                    flex-shrink: 0;
                    padding: 0;
                    justify-content: flex-start;
                    margin: 10px;
                    .btn-lng {
                        width: 160px;
                        margin-top: 25px;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    .s-hidden {
        display: none;
        visibility: hidden;
    }
    .s-show {
        display: flex;
        visibility: visible;
    }
    .header-pf {
        margin-bottom: 0;
        .pf-avt,
        .rk-avt {
            width: 66px;
            height: 66px;
            font-size: 34px;
            line-height: 66px;
        }
    }
    .modal.md-lv {
        .modal-dialog {
            width: 342px;
            margin: auto;
        }
    }
    #notif-dropdown {
        &.show {
            left: 0px !important;
        }
    }
    .modal-dialog.modal-tuto {
        margin: auto;
        width: 100vw;
        max-width: 100vw;
        min-height: 100vh;

        .tuto_close_mb {
            display: block;
            visibility: visible;
            font-weight: 400;
            font-size: 16px;
            font-family: "Roboto";
            color: $m-dark-75;
            margin: 30px 0 0 0;
            box-sizing: border-box;
            padding: 15px 10px 15px 0;
            background-color: transparent;
            text-transform: capitalize;
            -webkit-appearance: none;
            -moz-appearance: none;
            box-shadow: none;
            z-index: 5;
        }
        .tuto_close {
            display: none;
            visibility: hidden;
        }
        .carousel {
            padding-top: 0;
            padding-bottom: 0;
        }
        .ctn-indicators {
            display: flex;
            justify-content: space-between;
            padding: 20px 30px 20px 30px;
            align-items: center;
            flex-direction: column;
            ol.carousel-indicators {
                position: relative;
                right: unset;
                bottom: unset;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-left: 0;
                margin-right: 0;
                margin-bottom: 0;
                li {
                    height: 2px;
                    width: 53px;
                    margin-top: 0px;
                    background-color: $m-dark-50;
                    margin-right: 15px;
                    border-radius: 0;
                    &.active {
                        height: 6px;
                        background-color: $m-button;
                    }
                }
            }
            .ctn-btn-next {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
            }
            .btn-next {
                align-self: flex-end;
                margin-top: 30px;
            }
        }
        .modal-content {
            border-radius: 0;
            min-height: 100vh;
            background-image: unset;
        }
        .carousel-item {
            min-height: 385px;
        }
        .modal-body {
            padding: 0;
        }
        .ctn-tuto-txt {
            display: flex;
            width: 100%;
            padding: 0 0 0 0;
            justify-content: space-between;
            flex-direction: column-reverse;
            &.npright {
                padding-right: 0;
                padding-top: 0;
            }
            .ctn-txt {
                width: 100%;
                padding-left: 30px;
                padding-right: 30px;
            }
            .ctn-img {
                background-image: url("../images/bg_tuto_md.svg");
                background-position: center bottom;
                background-repeat: no-repeat;
                background-size: cover;
                padding-top: 30px;
                padding-bottom: 20px;
                width: auto;
                margin: 0;
                display: flex;
                justify-content: center;
                img.img-mb {
                    display: block;
                    visibility: visible;
                }
                img.img-dk {
                    display: none;
                    visibility: hidden;
                }
                &.nptop {
                    padding-top: 0;
                }
            }
            div {
                h2 {
                    margin-top: 30px;
                    font-weight: 500;
                    font-size: 34px;
                    font-family: "Roboto";
                    color: $m-primary;
                    margin-bottom: 20px;
                }
                p {
                    font-weight: 400;
                    font-size: 16px;
                    font-family: "Roboto";
                    color: $m-dark;
                }
            }
            &.helper {
                padding-right: 0;
            }
        }
    }
    // add css to make youtube embed video responsive
    .ctn-ct-qt .qt-container {
        .qt-text {
            padding-top: 0;
        }
    }
}
