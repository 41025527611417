@mixin body-page($width, $mg) {
    width: $width;
    margin-top: $mg;
    margin-bottom: $mg;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}
@mixin circle($wh, $bg, $ps) {
    position: $ps;
    width: $wh;
    height: $wh;
    border-radius: 50%;
    background-color: $bg;
    box-sizing: border-box;
}
@mixin span-si($wh, $lf, $bg) {
    position: absolute;
    border-radius: 50%;
    top: 0px;
    left: calc(50% + #{$lf});
    width: $wh;
    height: $wh;
    line-height: $wh;
    background-color: $bg;
}
@mixin default_police($fs, $weight, $ls, $ta) {
    font-size: $fs;
    font-weight: $weight;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: $ls;
    text-align: $ta;
}
@mixin ml($i) {
    margin-left: $i * 5%;
}
@mixin circle-list {
    @for $i from 2 through 19 {
        .circle-dist-#{$i} {
            @include ml($i);
        }
    }
}
@mixin mask-curve($pos, $bg-pos) {
    @extend %mask-curve;
    position: $pos;
    background-position: $bg-pos;
}
@mixin img-list {
    @for $i from 2 through 19 {
        .circle-dist-#{$i} {
            @include ml($i);
        }
    }
}
@mixin avatar-color {
    @for $i from 2 through 19 {
        .circle-dist-#{$i} {
            @include ml($i);
        }
    }
}

@mixin covid($size, $top, $left) {
    position: absolute;
    background-image: url('../images/co1.svg');
    background-position: top left;
    background-repeat: no-repeat;
    background-size: contain;
    overflow: hidden;
    width: $size;
    height: $size;
    top: $top;
    left: $left;
    z-index: -1;
}
