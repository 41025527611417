%nv-row {
    display: flex;
    flex-direction: row;
}
%nv-col {
    display: flex;
    flex-direction: column;
}
%flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
%full-height {
    height: 100vh;
}
%container-full-screen {
    position: relative;
    min-height: 100vh;
    min-width: 100vw;
}
%lg-mask {
    width: 100%;
    height: 50vh;
    background-color: $m-primary;
    z-index: 1030;
}
%ctn-flex-center {
    display: flex;
    justify-content: center;
}
%ctn-flex-center-center {
    @extend %ctn-flex-center;
    align-items: center;
    flex-direction: column;
}
%ctn-flex-center-row {
    @extend %ctn-flex-center;
    align-items: center;
    flex-direction: row;
}
%mask-curve {
    content: "";
    left: 0;
    width: 100%;
    height: 130px;
    background-repeat: no-repeat;
    background-size: contain;
}
%btn-base {
    left: calc(50% - 80px);
    width: 160px;
    height: 40px;
    border-radius: 10px;
    box-shadow: 0 1px 3px 0 $m-button;
    background-color: $m-button;
    color: $white;
    text-transform: uppercase;
    line-height: 40px;
    word-break: keep-all;
    border: 0;
    cursor: pointer;
}
%menu-item-base {
    cursor: pointer;
    text-transform: uppercase;
    box-sizing: border-box;
    width: 200px;
    margin: auto;
    padding-top: 18px;
    padding-bottom: 18px;
    height: 60px;
}
