// Variables
@import "variables";
@import "avatar-colors";

$fa-font-path: "./webfonts";

// Bootstrap
//@import '~bootstrap/scss/bootstrap';
@import "bootstrap";
// Font Awesome 5
//@import '../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss';
//@import '../../node_modules/@fortawesome/fontawesome-free/scss/solid.scss';
//@import '../../node_modules/@fortawesome/fontawesome-free/scss/brands';
//@import '../../node_modules/@fortawesome/fontawesome-free/scss/regular';
//@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome.scss";
//@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid.scss";
//@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
//@import "../../node_modules/@fortawesome/fontawesome-pro/scss/regular";

//@import '~@fortawesome/fontawesome-free/scss/fontawesome.scss';
//@import '~@fortawesome/fontawesome-free/scss/solid.scss';

// Variables
//@import 'spinners';
//import mdbootstrap
// Bootstrap
@import "core/bootstrap/functions";
@import "core/bootstrap/variables";
@import "core/bootstrap/rfs";
// CORE
@import "core/mixins";
// Your custom variables
//@import "custom-variables";
@import "core/colors";
@import "core/variables";
@import "core/global";
@import "core/helpers";
@import "core/typography";
@import "core/masks";
@import "core/waves";
// FREE
@import "free/animations-basic";
@import "free/modules/animations-extended/module";
@import "free/buttons";
@import "free/cards";
@import "free/dropdowns";
@import "free/input-group";
@import "free/navbars";
@import "free/pagination";
@import "free/badges";
@import "free/modals";
@import "free/carousels";
@import "free/forms";
@import "free/msc";
@import "free/footers";
@import "free/list-group";
@import "free/tables";
@import "free/depreciated";
@import "free/steppers";
@import "free/loader";
@import "free/treeview";
// Free addons
//DataTable CSS
@import "~datatables.net-buttons-dt/css/buttons.dataTables.css";
@import "~datatables.net-dt/css/jquery.dataTables.css";
@import "~datatables.net-select-dt/css/select.dataTables.css";
// Your custom styles
@import "custom-styles";
@import "mixins";
@import "class-base";
@import "md-form-custom";
//GENERAL
body {
    background-color: $white;
    position: relative;
}
.flex-center {
    @extend %flex-center;
}
.full-height {
    @extend %full-height;
}
.lk > a {
    color: $m-primary;
    padding: 0 25px;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: normal;
    text-decoration: none;
    text-transform: uppercase;
}
p.avatar-bg {
    margin: 0;
    height: 100%;
    width: 100%;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-weight: bold;
    font-family: Roboto;
}
//layout auth
.body-auth {
    background-color: $m-secondary;
    position: relative;
}
.ctn-auth {
    @extend %container-full-screen;
    background-color: $m-dark;
}
//LOGIN
.lg-mask-top {
    @extend %lg-mask;
    top: 0;
}
.lg-mask-bot {
    @extend %lg-mask;
    bottom: 0;
}
.lg-mask-bot.hide,
.lg-mask-bot.hg750.hide,
.lg-mask-top.hg750.hide,
.lg-mask-top.hide {
    height: 0;
    transition: 2s ease-out;
}
.ctn-lg-ct.hg750 {
    min-height: 750px;
}
.lg-mask-top.hg750,
.lg-mask-bot.hg750 {
    height: 375px;
}
.ctn-lg-ct {
    @extend %ctn-flex-center-center;
    margin: auto;
    height: 100vh;
    width: 100vw;
    position: fixed;
    color: $m-dark;
    z-index: 3000;
    .helper-ctn-lg {
        height: 500px;
        position: relative;
        @extend %ctn-flex-center;
        .prefix.active {
            color: $m-primary;
        }
        .prefix {
            color: $m-primary;
        }
    }
}
.div-lg-ctn {
    position: relative;
    margin-top: 10px;
    margin-bottom: 10px;
}
.ctn-mask-lg {
    @extend %ctn-flex-center-center;
    @extend %container-full-screen;
    position: fixed;
    z-index: 3000;
    opacity: 1;
}
.ctn-mask-lg.remove-opacity {
    opacity: 0;
    transition: 2s ease-out;
}
.ctn-mask-lg.hide {
    display: none;
    visibility: hidden;
}
.lg-logo {
    position: absolute;
    @extend %ctn-flex-center-center;
    top: 0;
    z-index: 10;
    background: $m-dark 0% 0% no-repeat padding-box;
    width: 292px;
    height: 185.3px;
    border-radius: 5px;
    box-shadow: 0 12px 17px 0 $m-dark-25;
    background-color: $white;
    img {
        width: 151px;
        height: auto;
    }
}
.lg-form {
    position: absolute;
    top: 158px;
    width: 342px;
    margin: auto;
    opacity: 1;
}
.ctn-lg-form {
    padding: 58px 25px 0 25px;
    width: 342px;
    background-color: $white;
    border-radius: 5px;
    box-shadow: 0 4px 5px 0 $m-dark-25;
    height: 330px;
    .nv-aw-ctn {
        .nv-custom-control-label {
            background-color: $white;
            padding-left: 2px;
            color: $m-dark;
        }
    }
}
.md-form {
    .form-control {
    }
    .prefix {
        width: 19px;
        height: 19px;
        font-size: 19px;
        top: calc(50% - 10px);
    }
    .validate {
        margin-bottom: 24px;
    }
    input {
        font-size: 16px;
    }
    input.form-control.active {
        border-bottom: 2px solid $m-primary;
    }
    label.active {
        -webkit-transform: translateY(-12px) scale(0.75);
        transform: translateY(-12px) scale(0.75);
    }
}
.md-form.div-lg-ctn.ipt {
    height: 56px;
}
.lk-psw {
    margin-top: 18px;
    text-transform: uppercase;
    display: block;
    position: relative;
    width: 100%;
    height: 14px;
    @include default_police(12px, 500, 1.08px, center);
    line-height: 1.17;
    color: $m-button;
    &:visited,
    &:active,
    &:focus,
    &:hover {
        color: $m-button;
    }
}
.btn-bs-lg {
    position: relative;
    @extend %btn-base;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &:hover {
        background-color: $m-button-50;
        box-shadow: none;
    }
    &:focus {
        outline: unset;
    }
}
.lg-ttl {
    width: 100%;
    height: 19px;
    @include default_police(16px, 500, 0.4px, center);
    line-height: 1.25;
    color: $m-primary;
    text-transform: uppercase;
    margin-bottom: 25px;
}
.bg-m-light {
    background-color: $m-light;
}
//MASK GENERAL
.mask-curve-top {
    top: $mask-curve-heigth-fixed;
}
.mask-curve-bot {
    bottom: -1px;
}
.mask-curve-bot-error {
    bottom: 54px;
}
.mask-curve-bot-footer {
    bottom: $mask-curve-heigth-fixed;
}
.mask-curve-top,
.footer-curve {
    @include mask-curve(absolute, center top);
}
.mask-curve-bot-error,
.mask-curve-bot,
.mask-curve-bot-footer {
    @include mask-curve(absolute, center bottom);
}
.mask-curve-bot-footer-relative {
    @include mask-curve(relative, center bottom);
    width: 100%;
    bottom: 54px;
}
.ctn-mask {
    position: relative;
    .mask-curve-top {
        top: -1px;
    }
}
//FOOTER GENERAL
.footer-curve-relative {
    @include mask-curve(absolute, bottom);
}
.footer-curve,
.footer-curve-relative {
    height: 57px;
    bottom: 0;
    display: flex;
    padding-right: 8.4%;
    padding-left: 8.4%;
    align-items: center;
    justify-content: space-between;
    .al-left {
        display: flex;
        flex-direction: row;
        align-items: center;
        img {
            height: auto;
            width: 100px;
            margin-right: 20px;
        }
        p {
            padding-left: 20px;
            @include default_police(10px, bold, 0.45px, left);
            line-height: 25px;
            color: $m-light;
            margin: 0;
            border-left: 1px solid $m-button;
        }
    }
    .al-right {
        img {
            height: 17px;
            width: auto;
        }
    }
}
//NAVBAR
.ppal-nav {
    z-index: 1040;
    position: fixed;
    width: 100vw;
    height: 100vh;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    top: 0;
    background-color: $blur-bg;
    display: none;
    visibility: hidden;
    transition: display 0.3s ease-out;
}
.ppal-nav.active {
    display: block;
    visibility: visible;
}
.ppal-nav.active.nb {
    -webkit-backdrop-filter: blur(0);
    backdrop-filter: blur(0);
}
.ctn-nav-ppal {
    z-index: 1050;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: -300vh;
    transition: top 0.6s ease-out;
    .ctn-ppal-nav {
        padding-top: 58px;
        background-color: $m-primary;
        .menu-item {
            @extend %menu-item-base;
            @include default_police(18px, 500, 1.62px, center);
            line-height: 22px;
            color: $white;
            border-bottom-width: 1px;
            border-bottom-color: $m-dark-25;
            border-bottom-style: solid;
            .ctn-sc {
                display: none;
                visibility: hidden;
            }
            a {
                display: block;
                color: $white;
                &:hover {
                    opacity: 0.75;
                }
            }
        }
        .menu-item-settings {
            @extend %menu-item-base;
            line-height: 12px;
            color: $m-light;
            display: flex;
            justify-content: space-between;
            align-content: center;
            align-items: center;
            @include default_police(12px, 500, 1.62px, center);
            a {
                display: block;
                color: $white-25;
            }
            button.btn-modal-menu {
                cursor: pointer;
                font-family: "Roboto";
                font-size: 12px;
                text-transform: uppercase;
                font-weight: 500;
                background-color: transparent;
                border: none;
                color: $white-25;
                -webkit-appearance: none;
                -moz-appearance: none;
                letter-spacing: 1.62px;
                &:focus {
                    outline: none;
                }
            }
        }
    }
}
.close-menu {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    padding-right: 30px;
    padding-top: 14px;
    width: 60px;
    height: 60px;
    line-height: 0;
    @include default_police(24px, normal, normal, right);
    color: $white;
}
.ctn-nav-ppal.active {
    top: 0;
}
.nav-bs {
    box-sizing: border-box;
    position: fixed;
    z-index: 1030;
    width: 100vw;
    height: $nav-heigth-fixed;
    background-color: $m-light;
    .ct-nav-bs {
        display: flex;
        box-sizing: border-box;
        position: relative;
        width: 100vw;
        height: $nav-heigth-fixed;
        justify-content: space-between;
    }
}
.burguer-btn {
    cursor: pointer;
    padding-right: 30px;
    color: $m-primary;
    box-sizing: border-box;
    padding-top: 12px;
    width: $nav-heigth-fixed;
    height: $nav-heigth-fixed;
    @include default_police(24px, normal, normal, right);
    line-height: 0;
}
.ctn-nav-btns {
    @extend %ctn-flex-center-row;
}
.ctn-sc {
    margin-left: 30px;
    height: $nav-heigth-fixed;
    @extend %ctn-flex-center-row;
    margin-right: 40px;
}
.ctn-sc:last-of-type {
    margin-left: 30px;
}
.txt-sc {
    @include default_police(20px, bold, 0.5px, left);
    line-height: 1.2;
    color: $m-primary;
}
.sc-avt {
    cursor: pointer;
    @include circle(29px, $transparent, relative);
    @include default_police(18px, bold, 0, center);
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    margin-right: 10px;
}
.snd-nav,
.trd-nav {
    position: fixed;
    top: 0;
    left: -375px;
    z-index: 2060;
    background-color: $m-light;
    width: 375px;
    height: 100vh;
    overflow: hidden;
    transition: left 200ms ease-in-out;
    .lv-pf-ctn {
        width: 342px;
        margin: auto;
        margin-top: 30px;
        min-height: 160px;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-content: center;
        .ml0 {
            top: 0;
        }
        .ml1 {
            top: 92px;
        }
        .ml2 {
            top: 160px;
        }
        .ml3 {
            top: 240px;
        }
        .mo0,
        .mo3,
        .mo6,
        .mo9,
        .mo12,
        .mo15,
        .mo18 {
            left: 25px;
        }
        .mo1,
        .mo4,
        .mo7,
        .mo10,
        .mo13,
        .mo16,
        .mo19 {
            left: 146px;
        }
        .mo2,
        .mo5,
        .mo8,
        .mo11,
        .mo14,
        .mo17,
        .mo20 {
            left: 267px;
        }
        .mll0 {
            top: 0;
        }
        .mll1 {
            top: 92px;
        }
        .mll2 {
            top: 160px;
        }
        .mll3 {
            top: 240px;
        }
        .mol0,
        .mol3,
        .mol6,
        .mol9,
        .mol12,
        .mol15,
        .mol18,
        .mol21 {
            display: none;
        }
        .mol1,
        .mol4,
        .mol7,
        .mol10,
        .mol13,
        .mol16,
        .mol19,
        .mol22 {
            left: 75px;
        }
        .mol2,
        .mol5,
        .mol8,
        .mol11,
        .mol14,
        .mol17,
        .mol20,
        .mol23 {
            left: 197px;
        }
        .dol5,
        .dol10,
        .dol15 {
            display: block;
        }
        .ln-snd-lv {
            position: absolute;
            top: 25px;
        }
    }
    .pf-sc {
        margin: auto;
    }
    .header-pf {
        margin-top: 60px;
    }
    .close-menu {
        height: $nav-heigth-fixed;
        background-color: $m-primary;
        text-align: right;
        width: 375px;
        padding-right: 15px;
    }
    .ctn-mask {
        top: $mask-curve-heigth-fixed;
        width: 100vw;
    }
}
.snd-nav.active,
.trd-nav.active {
    left: 0;
    box-shadow: 12px 0 17px 0 $m-dark-25;
}
.lv-pf {
    line-height: 50px;
    color: $white;
    box-shadow: 0 4px 5px 0 $m-headers-50;
    border-radius: 50%;
    @include default_police(35px, bold, normal, center);
    @include circle(50px, $m-headers, absolute);
    cursor: pointer;
    z-index: 1029;
    &:hover,
    &:focus,
    &:visited {
        box-shadow: unset;
        color: $white;
    }
}
.lv-pf.disabled {
    background-color: $m-headers-50;
}
.ctn-gm-hm {
    position: relative;
    color: $white;
    padding-top: $nav-heigth-fixed;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    .header-order {
        @include circle(99px, $m-headers, absolute);
        z-index: 5;
        top: $nav-heigth-fixed;
        @include default_police(73px, bold, normal, center);
        left: calc(50% - 50px);
        line-height: 99px;
        color: $white;
    }
}
.ctn-gm-pf {
    position: relative;
    color: $white;
    padding-top: $nav-heigth-fixed;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0px;
    display: flex;
    flex-direction: column;
}
.lv-pf-ctn {
    min-height: 160px;
    margin-top: 30px;
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: center;
    .dl0 {
        top: 0;
    }
    .dl1 {
        top: 94px;
    }
    .dl2 {
        top: 160px;
    }
    .dl3 {
        top: 240px;
    }
    .do0,
    .do5,
    .do10,
    .do15 {
        left: 25px;
    }
    .do1,
    .do6,
    .do11,
    .do16 {
        left: 146px;
    }
    .do2,
    .do7,
    .do12,
    .do17 {
        left: 267px;
    }
    .do3,
    .do8,
    .do13,
    .do18 {
        left: 388px;
    }
    .do4,
    .do9,
    .do14,
    .do19 {
        left: 509px;
    }
    .dll0 {
        top: 0;
    }
    .dll1 {
        top: 94px;
    }
    .dll2 {
        top: 188px;
    }
    .dll3 {
        top: 276px;
    }
    .dol0,
    .dol5,
    .dol10,
    .dol15 {
        display: none;
    }
    .dol1,
    .dol6,
    .dol11,
    .dol16 {
        left: 75px;
    }
    .dol2,
    .dol7,
    .dol12,
    .dol17 {
        left: 197px;
    }
    .dol3,
    .dol8,
    .dol13,
    .dol18 {
        left: 317px;
    }
    .dol4,
    .dol9,
    .dol14,
    .dol19 {
        left: 439px;
    }
    .ln-snd-lv {
        position: absolute;
    }
    .ln-snd-lv-mb {
        display: none;
    }
    .ln-snd-lv-mb.ll1,
    .ln-snd-lv.ll1 {
        top: 25px;
    }
    .ln-snd-lv-mb.ll2,
    .ln-snd-lv.ll2 {
        top: 50px;
    }
}
.div-line-lv {
    width: 70px;
    padding-left: 2px;
    padding-right: 2px;
    position: absolute;
    hr.active {
        margin-top: 25px;
        margin-bottom: 24px;
        border-top-width: 0.5px;
        border-top-color: $m-primary;
    }
}
.div-line-lv-disabled {
    width: 70px;
    padding-left: 2px;
    padding-right: 2px;
    position: absolute;
    hr {
        margin-top: 25px;
        margin-bottom: 24px;
        border-top-width: 0.5px;
        border-top-color: $m-primary;
    }
}
.pf-sc,
.pf-sc-by-lv {
    position: relative;
    margin: 0 auto;
    .bg-ccl {
        position: absolute;
        border-radius: 50%;
        background-color: $m-helper;
    }
    .cc1 {
        height: 37px;
        width: 37px;
        top: 40px;
        left: 6px;
    }
    .cc2 {
        height: 22px;
        width: 22px;
        top: 0;
        right: 36px;
    }
    .cc3 {
        height: 13px;
        width: 13px;
        top: 40px;
        right: 4px;
    }
    .cc4 {
        height: 22px;
        width: 22px;
        bottom: 2px;
    }
    .cc5 {
        height: 48px;
        width: 48px;
        bottom: 0;
        right: 0;
    }
}
.pf-sc {
    width: 186px;
    height: 186px;
}
.pf-sc-by-lv {
    width: 100%;
    max-width: 290px;
}
.pf-sc-center {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 160px;
    height: 160px;
    bottom: 0;
    left: calc(50% - 80px);
    background-color: $m-button;
    box-shadow: 0 9px 12px 0 $m-button-50;
    border-radius: 50%;
    @include default_police(49px, bold, 1.23px, center);
    line-height: 1.2;
    color: $white;
    i {
        font-size: 33px;
        color: $m-light;
    }
    span.label {
        text-transform: uppercase;
        line-height: 1.21;
        color: $white;
        @include default_police(14px, normal, 1.26px, center);
    }
    span.label.avg {
        font-size: 11px;
    }
}
.navbar {
    padding: 0;
}
.nv-logo {
    height: 30px;
    width: auto;
}
li.nav-item.dropdown {
    padding: 10px 20px 0 20px;
}
.row {
    margin-bottom: 20px;
}
.card {
    margin-bottom: 20px;
}
li.remove_bullet {
    list-style: none;
}
.ctn {
    max-width: 100%;
}
.nv-opposite {
    justify-content: space-between;
}
td,
.nv-center {
    align-self: center;
}
.nv-root {
    font-weight: 700;
}
.nv-child {
    padding-left: 25px;
    height: 40px;
    .dropdown {
        opacity: 0;
    }
}
.nv-child:hover {
    .dropdown {
        opacity: 1;
    }
}
.table td {
    vertical-align: middle;
}
.table tr {
    vertical-align: middle;
    .dropdown {
        opacity: 0;
    }
}
.table tr:hover {
    &:hover {
        .dropdown {
            opacity: 1;
        }
    }
}
.nv-ctn-flex {
    display: flex;
    flex-wrap: wrap;
}
a.btn {
    font-weight: 400;
    color: $white;
}
.dropdown:focus {
    outline-width: 0;
}
.btn:focus {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}
ul.navbar-nav.nv-opposite {
    width: 100%;
    flex-direction: row;
}
.icon-aw {
    bottom: 18px;
    left: 25px;
    line-height: 13px;
    color: $white;
    @include circle(13px, rgba(255, 255, 255, 0), absolute);
    @include default_police(9px, normal, normal, center);
}
.icon-aw.correct {
    background-color: $m-button;
}
.icon-aw.wrong {
    background-color: $m-errors;
}
.qt-disable {
    text-align: right;
    border-color: rgba(200, 200, 200, 0.7);
    background-color: rgba(200, 200, 200, 0.2);
    color: rgba(200, 200, 200, 0.9);
}
.nv-fb {
    position: relative;
    width: 100%;
    min-height: 1px;
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 0.8);
    margin-right: 6px;
    border-color: #ffffff;
    border-width: 2px;
    border-style: solid;
    margin-top: 30px;
    padding: 18px;
    margin-left: 6px;
}
.nv-hidden {
    display: none;
    visibility: hidden;
}
.nv-hidden.active {
    display: block;
    visibility: visible;
}
.pointer {
    cursor: pointer;
}
.custom-control-label {
    &:target {
        border: 5px solid orange;
    }
}
span.field-icon {
    position: absolute;
    display: inline-block;
    cursor: pointer;
    right: 0;
    top: 0;
    color: $m-dark-50;
    z-index: 33;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
/*Home Page*/
body.with-menu {
    width: 100%;
    height: 100vh;
    .footer-curve-relative,
    .footer-curve-relative {
        bottom: unset;
    }
}
.header-pf {
    width: 100%;
    position: relative;
}
.btn-jk {
    display: none;
    visibility: hidden;
}
.jk-icon-hidde {
    display: none;
    visibility: hidden;
}
.welcome-user {
    z-index: 5;
    position: absolute;
    top: 60px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    left: 12.5%;
    .text-fixed {
        @include default_police(79px, 300, normal, left);
        line-height: 1.2;
        color: $m-primary;
    }
    .text-nm {
        @include default_police(79px, 500, normal, left);
        line-height: 1.2;
        color: $m-button;
    }
}
.msg-box {
    position: absolute;
    top: 350px;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    left: 12.5%;
    right: 8%;
    .msg-text {
        @include default_police(50px, 450, normal, left);
        color: $m-primary;
        u {
            span.underline {
                height: 2vh;
            }
        }
    }
}
.bg-hm {
    // background-image: url("../images/photo_head_INTRO_3x.jpg");
    height: calc(100vh - 58px);
    width: 70%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom right;
}
.ctn-intro {
    position: absolute;
    display: flex;
    justify-content: space-between;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    padding: 55px;
    max-width: 600px;
    max-height: calc(100vh - 300px);
    flex-direction: column;
    box-shadow: 0 4px 5px 0 $m-dark-25;
    background-color: $white;
    top: 50%;
    left: 70%;
    h1 {
        @include default_police(34px, 500, 0.85px, left);
        line-height: 1.12;
        color: $m-primary;
    }
    h1.with-rc {
        @include default_police(18px, 500, 0.45px, left);
        line-height: 1.11;
        color: $m-primary;
        margin-bottom: 20px;
        u {
            span.underline {
                height: 8px;
            }
        }
    }
    .intro-text {
        /* Track */
        ::-webkit-scrollbar-track {
            background: $m-light;
        }
        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: $m-primary;
        }
        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: $m-primary;
        }
        /* width */
        ::-webkit-scrollbar {
            width: 4px;
        }
        p {
            max-height: calc(100vh - 580px);
            overflow: auto;
            font-family: "Roboto", serif;
            @include default_police(15px, normal, normal, left);
            line-height: 1.29;
            color: $m-dark;
            flex-grow: 1;
            flex-shrink: 1;
            scrollbar-color: $m-primary $m-light;
            scrollbar-width: thin;
        }
    }
    .start-btn {
        align-self: center;
        display: block;
        text-transform: uppercase;
        width: 160px;
        border-radius: 10px;
        box-shadow: 0 1px 3px 0 $m-button;
        color: $white;
        height: 40px;
        background-color: $m-button;
        line-height: 40px;
        cursor: pointer;
        @include default_police(14px, 500, 1.26px, center);
        &:hover {
            background-color: $m-button-50;
            box-shadow: none !important;
        }
    }
    .pn-with-rc.completed {
        background-color: $m-headers;
    }
    .pn-with-rc {
        display: flex;
        flex-direction: column;
        padding: 12px;
        width: 100%;
        border-radius: 10px;
        background-color: $m-headers;
        .fst-ln {
            display: flex;
            flex-direction: row;
            align-items: center;
            color: $white-50;
            font-size: 34px;
            h2 {
                font-size: 18px;
                font-weight: bold;
                margin-left: 16px;
                margin-bottom: 0;
            }
        }
        .snd-ln {
            display: flex;
            flex-direction: row;
            margin-top: 4px;
            color: $white;
            @include default_police(14px, normal, 1.12px, left);
            line-height: 1.29;
            justify-content: space-between;
        }
        .txt-btn-go {
            @include default_police(14px, normal, 1.12px, left);
            line-height: 1.29;
            align-self: center;
        }
        .btn-go {
            @include circle(62px, $white, relative);
            box-shadow: 0 3px 6px 0 $m-dark-25;
            cursor: pointer;
            @include default_police(20px, bold, normal, center);
            line-height: 62px;
            color: $m-headers;
            &:hover {
                box-shadow: none !important;
            }
        }
    }
}
.ctn-lv-hm {
    position: relative;
    width: 870px;
    margin: auto;
    display: flex;
    flex-direction: column;
    padding-top: 116px;
    padding-bottom: 216px;
}
.ctn-lv-ccl {
    position: relative;
    width: 100%;
    overflow: hidden;
}
.ctn-lv-ccl.without_records {
    display: none !important;
}
.ctn-lv-ccl.has-records {
    display: block !important;
}
.mask-curve-bot-footer-relative.without_records,
.footer-curve-relative.without_records {
    display: none !important;
}
.ctn-lv-item {
    width: 340px;
    position: relative;
    .lv-number {
        z-index: 3;
        margin-left: calc(50% - 25px);
        line-height: 50px;
        color: $white;
        @include default_police(35px, bold, normal, center);
        @include circle(50px, $m-headers, relative);
    }
    .lv-img {
        position: relative;
        margin-top: -25px;
        margin-left: 11px;
        width: 320px;
        height: 212px;
        border-radius: 10px;
        box-shadow: 0 6px 10px 0 $m-dark-25;
        z-index: 2;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    .lv-mask {
        position: absolute;
        top: 25px;
        left: 10px;
        width: 321px;
        height: 212px;
        border-radius: 10px;
        z-index: 2;
        background-color: $m-dark-75;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;
        @include default_police(16px, 500, 0.4px, center);
        line-height: 1.25;
    }
    .lv-ctn-ttl {
        z-index: -1;
        margin-top: -140px;
        .lv-ttl {
            position: relative;
            width: 342px;
            box-sizing: border-box;
            padding-top: 152px;
            padding-left: 10px;
            padding-right: 10px;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            padding-bottom: 12px;
            background-color: $m-primary;
            h2 {
                text-transform: uppercase;
                color: $white;
                margin: 0;
                @include default_police(25px, bold, 2px, center);
                line-height: 1.2;
            }
        }
        .lv-info {
            position: relative;
            padding-left: 10px;
            background-color: $m-light;
            padding-top: 7px;
            box-shadow: 0 4px 5px 0 $m-dark-25;
            height: 62px;
            width: 342px;
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
            .lv-group,
            .lv-res {
                position: relative;
                width: 48px;
                height: 48px;
                display: inline-block;
                .lv-icon {
                    @include default_police(25px, normal, normal, center);
                    cursor: pointer;
                    font-size: 25px;
                    line-height: 48px;
                    color: $m-headers;
                    -webkit-appearance: unset;
                }
                .lv-icon.current {
                    color: $m-primary;
                }
                .lv-icon.disabled {
                    opacity: 0.4;
                }
                .lv-counter-g,
                .lv-counter-r {
                    @include circle(13px, $m-dark, absolute);
                    top: 5px;
                    z-index: 5;
                    @include default_police(9px, bold, normal, center);
                    line-height: 13px;
                    color: $white;
                }
                .lv-counter-g {
                    right: 3px;
                }
                .lv-counter-r {
                    right: 6px;
                }
            }
            .lv-btn,
            .lv-btn-dd {
                cursor: pointer;
                position: absolute;
                right: 14px;
                top: 10px;
                width: 160px;
                height: 40px;
                border-radius: 10px;
                box-shadow: 0 1px 3px 0 $m-button;
                background-color: $m-button;
                a {
                    text-transform: uppercase;
                    display: block;
                    line-height: 40px;
                    color: $white;
                    @include default_police(14px, 500, 1.26px, center);
                }
                &:hover {
                    background-color: $m-button-50;
                }
            }
            .lv-btn.completed {
                box-shadow: 0 1px 3px 0 $m-headers;
                background-color: $m-headers;
            }
            .lv-btn:hover,
            .lv-btn-dd {
                box-shadow: none !important;
            }
            .lv-mask-btn {
                cursor: no-drop;
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                right: 14px;
                top: 10px;
                width: 160px;
                height: 40px;
                border-radius: 10px;
                background-color: $m-dark-50;
                color: $white;
                @include default_police(16px, 500, 0.4px, center);
                line-height: 1.25;
            }
        }
    }
}
.ctn-media {
    position: relative;
    display: flex;
    flex-direction: row;
    margin-top: -12px;
}
.fst-lv {
    justify-content: flex-end;
}
.snd-lv {
    justify-content: flex-start;
}
.ctn-ccl {
    width: 100%;
    max-width: 1280px;
    left: 50%;
    transform: translate(-50%);
    position: absolute;
    top: 0;
    z-index: -1;
}
.ccl-0 {
    @include covid(224px, 250px, 50%);
}
.ccl-1 {
    @include covid(300px, 500px, -180px);
}
.ccl-2 {
    @include covid(170px, 700px, 100%);
    left: unset;
    right: -77px;
}
.ccl-3 {
    @include covid(224px, 1150px, 22.5%);
}
.ccl-4 {
    @include covid(224px, 1220px, 77.5%);
}
.ccl-5 {
    @include covid(170px, 1850px, 37.5%);
}
.ccl-6 {
    @include covid(300px, 1980px, 37.5%);
    left: unset;
    right: 0;
}
.ccl-7 {
    @include covid(225px, 2300px, 20%);
    left: unset;
    right: 0;
}

.ccl-last {
    @include covid(225px, 0, 57%);
    top: unset;
    bottom: -50px;
}
.ctn-intro-lv {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 56px;
    left: 70%;
    width: 30%;
    height: calc(100vh - 56px);
    padding-left: 26px;
    padding-right: 26px;
    h1 {
        text-transform: uppercase;
        @include default_police(25px, bold, 2px, center);
        line-height: 1.2;
        color: $white;
        margin-bottom: 24px;
    }
    p {
        @include default_police(15px, normal, normal, left);
        font-family: Roboto;
        line-height: 1.29;
        color: $white;
    }
}
.ctn-qt-lt {
    font-family: Roboto;
}
.ctn-ct-lv {
    display: flex;
    flex-direction: row;
    padding-top: 100px;
    padding-bottom: 50px;
    margin-bottom: 50px;
    .ctn-qt-lt {
        width: 70%;
        padding-left: 8.4%;
        padding-right: 26px;
        font-family: Roboto;
        border-right: solid 1px $m-dark-25;
    }
    .ctn-bn {
        box-sizing: border-box;
        width: 30%;
        padding-left: 26px;
        padding-right: 8.4%;
    }
}
.ct-bn {
    overflow-wrap: break-word;
    h2 {
        @include default_police(14px, 500, 0.35px, left);
        line-height: 1.43;
        margin: 0;
        color: $m-primary;
    }
    p {
        font-family: "Roboto", serif;
        line-height: 1.29;
        color: $m-dark;
        margin: 0;
        @include default_police(14px, normal, normal, left);
        padding-bottom: 12px;
    }
}
.ttl-bn {
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    .ttl-icon {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 30px;
        @include circle(30px, $m-bonus, inherit);
        color: $white;
        margin-right: 10px;
    }
    h2 {
        margin: 0;
        @include default_police(16px, normal, 0.4px, left);
        line-height: 30px;
        color: $m-dark;
        flex-grow: 1;
        flex-shrink: 1;
    }
    .open-close-collapse {
        align-self: flex-end;
        flex-grow: 0;
        flex-shrink: 0;
    }
    &[aria-expanded="true"] {
        .fa-chevron-down {
            visibility: hidden;
            display: none;
        }
        .fa-chevron-up {
            visibility: visible;
        }
    }
    &[aria-expanded="false"] {
        .fa-chevron-up {
            visibility: hidden;
            display: none;
        }
        .fa-chevron-down {
            visibility: visible;
        }
    }
}
.or-qt {
    @include circle(30px, $m-primary, inherit);
    margin: 2px 10px 2px 2px;
    flex-grow: 0;
    @include default_police(20px, bold, 0.5px, center);
    line-height: 30px;
    color: $white;
    flex-shrink: 0;
}
.dd-item {
    .or-qt {
        background-color: $transparent;
        margin-top: 0;
        margin-bottom: 0;
    }
}
.ctn-qt-lt-pf {
    width: 100%;
}
.card-nv {
    position: relative;
    .line-btw {
        border-left: solid 1px $m-dark-25;
        margin-top: 2px;
        margin-bottom: 2px;
        margin-left: 17px;
        height: 16px;
    }
    a,
    .qt-disabled {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: row;
        min-width: 0;
        word-wrap: break-word;
        background-clip: border-box;
        line-height: 34px;
        color: $m-dark;
        overflow: hidden;
        white-space: nowrap;
        @include default_police(16px, 500, 0.4px, left);
        .qt-disabled-bg {
            width: 100%;
            display: block;
            height: 100%;
            border-radius: 18px;
            -webkit-backdrop-filter: blur(1px);
            backdrop-filter: blur(1px);
            background-color: $m-errors-75;
            position: absolute;
            top: 0;
            left: 0;
        }
        .qt-disabled-time {
            width: 100%;
            display: block;
            height: 100%;
            border-radius: 18px;
            color: $white;
            position: absolute;
            line-height: 34px;
            top: 0;
            left: 0;
            @include default_police(16px, 500, 0.4px, center);
        }
        span {
            @include circle(30px, $m-primary, inherit);
            flex-grow: 0;
            flex-shrink: 0;
            margin-top: 2px;
            margin-left: 2px;
            margin-bottom: 2px;
            margin-right: 10px;
            @include default_police(20px, bold, 0.5px, center);
            line-height: 30px;
            color: $white;
            top: 0;
            left: 0;
        }
    }
    .a-qt {
        position: relative;
        text-overflow: ellipsis;
        min-width: 0;
        background-clip: border-box;
        line-height: 34px;
        color: $m-dark;
        @include default_police(16px, 500, 0.4px, left);
        margin-bottom: 0;
        overflow: hidden;
        white-space: nowrap;
        word-wrap: break-word;
    }
}
.ctn-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding-bottom: 185px;
    background-color: $white;
}
.ctn-res {
    position: relative;
    width: 100%;
    padding-left: 8.4%;
    padding-right: 8.4%;
    padding-top: 40px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: $m-light;
    padding-bottom: 250px;
    .res-ttl {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        h2 {
            @include default_police(16px, 500, 0.4px, center);
            line-height: 19px;
            color: $m-primary;
            border-bottom: 2px solid $m-bonus;
        }
    }
    .res-ct {
        padding-top: 40px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        a {
            margin: 0;
            width: 25%;
            flex-grow: 0;
            flex-shrink: 0;
            padding: 0 5px;
            @include default_police(16px, 500, 0.4px, left);
            line-height: 1.25;
            color: $m-dark;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            .ctn-icon-res {
                @include circle(30px, $m-dark, inherit);
                margin-right: 20px;
                color: $white;
                text-align: center;
                line-height: 30px;
                flex-grow: 0;
                flex-shrink: 0;
            }
        }
    }
}
.res-ttl {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    h2 {
        @include default_police(16px, 500, 0.4px, center);
        line-height: 19px;
        color: $m-primary;
        border-bottom: 2px solid $m-bonus;
    }
}
.btn-back {
    display: none;
    visibility: hidden;
}
.ctn-qt-hm {
    position: relative;
    display: flex;
    flex-direction: row;
    min-height: 100vh;
    .choix-txt {
        margin-top: 20px;
        margin-bottom: 20px;
        color: $m-primary;
        padding-left: 42px;
        @include default_police(14px, normal, 0.35px, left);
        line-height: 1.43;
    }
    h1 {
        z-index: 5;
        position: absolute;
        left: calc(11% + 80px);
        top: 80px;
        display: flex;
        color: $m-primary;
        @include default_police(25px, bold, normal, left);
        line-height: 1.2;
        text-transform: uppercase;
        align-items: center;
        height: 60px;
        justify-content: center;
    }
    .order-qt {
        @include circle(60px, $m-headers, absolute);
        flex-shrink: 0;
        line-height: 60px;
        @include default_police(42px, bold, normal, center);
        flex-grow: 0;
        color: $white;
        margin-right: 20px;
        left: 12%;
        top: 82px;
        z-index: 5;
    }
    .ctn-ct-qt {
        padding: 180px 26px 180px 12%;
        width: 64%;
        margin-bottom: 50px;
        h2 {
            @include default_police(16px, 500, 0.4px, left);
            line-height: 1.25;
            display: flex;
            align-items: center;
            color: $m-dark;
            span {
                align-self: flex-start;
            }
        }
    }
    .ctn-jk {
        width: 36%;
        padding: 180px 12% 180px 26px;
        min-height: 100vh;
    }
}
.jk-box {
    border-radius: 8px;
    background-color: $m-button;
    padding-left: 9px;
    padding-right: 9px;
    padding-bottom: 10px;
    margin-bottom: 25px;
    .jk-ttl {
        display: flex;
        flex-direction: row;
        justify-content: center;
        color: $m-dark-50;
        text-transform: uppercase;
        border-bottom-color: $white-25;
        border-bottom-style: solid;
        border-bottom-width: 0.5px;
        @include default_police(16px, 500, 1.43px, center);
        line-height: 51px;
    }
    .img-jk {
        margin-left: 12px;
        width: 33px;
        height: auto;
        img {
            width: 100%;
        }
    }
    .jk-ct {
        h2 {
            margin-top: 13px;
            color: $white;
            margin-bottom: 13px;
            @include default_police(16px, 500, 0.4px, center);
            line-height: 1.25;
            span {
                @include default_police(20px, bold, 0.5px, center);
                line-height: 1.2;
                color: $white;
            }
        }
        .jk-item {
            cursor: pointer;
            box-sizing: border-box;
            border-radius: 4px;
            position: absolute;
            height: 41px;
            box-shadow: 0 1px 3px 0 $m-dark-25;
            background-color: $white;
            text-align: center;
            margin-bottom: 14px;
            display: flex;
            flex-direction: column;
            transition: top 0.4s ease-in-out, height 0.4s ease-in-out;
            width: 100%;
            z-index: 1010;
            overflow: hidden;
            h3 {
                @include default_police(16px, 500, 0.35px, center);
                line-height: 41px;
                margin-left: 35px;
                margin-bottom: 0;
                letter-spacing: 0.35px;
                color: $m-primary;
                flex-grow: 1;
                flex-shrink: 1;
            }
            .coin-png {
                margin-right: 5px;
                width: 36px;
                height: 35px;
                z-index: 1000;
                display: flex;
                flex-direction: column;
                justify-content: center;
                background-image: url("../images/coin.png");
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
                flex-grow: 0;
                flex-shrink: 0;
                p {
                    margin-top: 0;
                    margin-bottom: 0;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 0;
                    text-align: center;
                    line-height: 0.55;
                    color: $white;
                }
                .p-number {
                    font-size: 16px;
                    font-weight: bold;
                    letter-spacing: 0.28px;
                    text-shadow: 1px 1px 3px $m-dark;
                    span {
                        text-transform: lowercase;
                        font-size: 12px;
                        font-weight: normal;
                        letter-spacing: 0.2px;
                    }
                }
            }
            .ds-jk {
                padding-right: 9px;
                padding-left: 9px;
                flex-direction: column;
                z-index: 1015;
                padding-bottom: 9px;
                justify-content: space-between;
                display: flex;
                /* Track */
                ::-webkit-scrollbar-track {
                    background: $m-light;
                }
                /* Handle */
                ::-webkit-scrollbar-thumb {
                    background: $m-secondary;
                }
                /* Handle on hover */
                ::-webkit-scrollbar-thumb:hover {
                    background: $m-secondary;
                }
                /* width */
                ::-webkit-scrollbar {
                    width: 4px;
                }
                h3 {
                    margin: 0;
                    font-size: 14px;
                    text-align: left;
                    line-height: 20px;
                    flex-grow: 0;
                    flex-shrink: 0;
                    border-top: solid 0.5px $m-dark-25;
                    padding-top: 5px;
                    .ds-jk-cost {
                        float: left;
                    }
                    .ds-jk-qty {
                        color: $m-headers;
                        float: right;
                    }
                }
                p {
                    font-family: "Roboto", serif;
                    margin: 0;
                    font-size: 14px;
                    text-align: left;
                    font-weight: normal;
                    line-height: 1.2;
                    flex-grow: 0;
                    flex-shrink: 1;
                    overflow-y: auto;
                    scrollbar-color: $m-secondary m-light;
                    padding-bottom: 4px;
                    scrollbar-width: thin;
                }
            }
        }
        .jk-item.disabled {
            cursor: auto;
            opacity: 0.5;
            z-index: 0;
        }
        .jk-item.selected {
            cursor: auto;
            opacity: 1;
            z-index: 0;
        }
        .jk-item.unselected {
            z-index: 0;
        }
    }
}
.nv-aw-ctn {
    width: 100%;
    position: relative;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    align-content: stretch;
    .checkbox-mask {
        flex-grow: 0;
        flex-shrink: 0;
        cursor: pointer;
        z-index: 2;
        width: 29px;
        height: 29px;
        border-radius: 10px;
        background-color: $m-primary-25;
        display: flex;
        align-items: center;
        justify-content: center;
        i,
        svg {
            display: none;
            color: $white;
            visibility: hidden;
            line-height: 29px;
            @include default_police(16px, 900, 0.4px, center);
        }
    }
    .checkbox-mask.checked {
        background-color: $m-primary;
        i,
        svg {
            display: block;
            visibility: visible;
        }
    }
    .radio-mask {
        cursor: pointer;
        z-index: 2;
        @include circle(29px, $m-primary-25, relative);
        top: calc(50% - 14px);
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 0;
        flex-shrink: 0;
        i,
        svg {
            display: none;
            visibility: hidden;
            color: $white;
            @include default_police(16px, 900, 0.4px, center);
            line-height: 29px;
        }
    }
    .radio-mask.checked {
        background-color: $m-primary;
        i,
        svg {
            display: block;
            visibility: visible;
        }
    }
    .radio-mask.checked.nv-correct,
    .radio-mask.nv-correct,
    .checkbox-mask.nv-correct,
    .checkbox-mask.checked.nv-correct {
        background-color: $m-button;
    }
    .radio-mask.checked.nv-wrong,
    .checkbox-mask.checked.nv-wrong {
        background-color: $m-errors;
    }
    input.nv-custom-control-input {
        opacity: 0;
        z-index: -3;
        height: 1px;
        width: 1px;
        position: absolute;
        top: 50%;
        left: 10px;
    }
    .nv-custom-control-label {
        cursor: pointer;
        padding-left: 9px;
        padding-right: 9px;
        margin-left: 13px;
        display: flex;
        align-items: center;
        min-height: 29px;
        border-radius: 10px;
        background-color: $m-light;
        @include default_police(16px, normal, 0.4px, left);
        color: $m-dark;
        margin-bottom: 0;
        flex-grow: 1;
    }
    .teammates-qty {
        right: 0;
        top: calc(50% - 17px);
        @include circle(34px, $m-primary, absolute);
        @include default_police(20px, bold, 0.88px, center);
        line-height: 34px;
        color: $white;
    }
}
.nv-aw-fb {
    width: 100%;
    border-radius: 10px;
    padding-top: 16px;
    padding-bottom: 17px;
    padding-left: 13px;
    padding-right: 13px;
    .fb-ttl {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        margin-bottom: 20px;
        .icon-fb {
            width: 34px;
            height: 35px;
            margin-right: 14px;
            font-size: 34px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.29;
            letter-spacing: normal;
            text-align: left;
            color: $m-light;
        }
        h2 {
            margin-top: 0;
            margin-bottom: 0;
            text-transform: uppercase;
            font-family: Roboto;
            font-size: 18px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.11;
            letter-spacing: 1.44px;
            text-align: left;
            color: $m-light;
        }
    }
    p {
        float: none;
        font-family: Roboto;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        margin-bottom: 0;
        letter-spacing: 0.35px;
        text-align: left;
        color: $white;
        margin-top: 0;
        a {
            color: $white;
            text-decoration: underline;
            overflow-wrap: break-word;
            word-wrap: break-word;
            &:hover {
                opacity: 0.8;
            }
        }
    }
    .lk-fb {
        width: 100%;
        text-align: right;
        margin-top: 22px;
        margin-bottom: 0;
        a {
            margin-top: 0;
            margin-bottom: 0;
            text-transform: uppercase;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.21;
            letter-spacing: 1.26px;
            text-align: center;
            color: $white;
        }
    }
}
.nv-aw-fb.fb-correct {
    background-color: $m-button;
}
.nv-aw-fb.fb-wrong {
    background-color: $m-errors;
}
.nv-custom-control-label.disabled,
.radio-mask.disabled,
.checkbox-mask.disabled {
    cursor: auto;
}
.btn-submit-qt {
    text-transform: uppercase;
    width: 160px;
    height: 40px;
    border-radius: 10px;
    box-shadow: 0 1px 3px 0 $m-button;
    background-color: $m-button;
    @include default_police(14px, 500, 1.26px, center);
    line-height: 40px;
    color: $white;
    float: right;
    cursor: pointer;
    z-index: 3;
    &:hover {
        box-shadow: none !important;
    }
}
.snd-time.btn-submit-qt {
    background-color: $m-button;
    box-shadow: 0 1px 3px 0 $m-button;
}
//PROFILE
img.avt {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    vertical-align: top;
    border: solid 2px $m-primary;
}
.header-pf {
    z-index: 5;
    clear: both;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 30px;
    .pf-avt {
        @include circle(99px, $transparent, inherit);
        z-index: 5;
        color: $white;
        @include default_police(49px, bold, 1.23px, center);
        line-height: 99px;
        span {
            display: flex;
            align-items: center;
            justify-content: center;
            @include circle(20px, $m-headers, absolute);
            top: 0;
            @include default_police(14px, normal, normal, center);
            line-height: 20px;
            left: calc(50% + 23px);

            .svg-inline--fa {
                vertical-align: middle;
            }
        }
    }
    .rk-avt {
        z-index: 5;
        color: $white;
        border: solid 2px $m-bonus;
        @include circle(99px, $m-bonus, inherit);
        @include default_police(52px, normal, 0, center);
        line-height: 99px;
    }
    .pf-nm {
        text-transform: uppercase;
        margin-top: 22px;
        width: 100%;
        @include default_police(18px, bold, 1.44px, center);
        line-height: 1.11;
        color: $m-primary;
    }
}
.body-pf {
    @include body-page(900px, 50px);
    .row-pf {
        margin-bottom: 40px;
        display: flex;
        flex-direction: row;
        .pf-pro {
            width: 584px;
        }
        .pf-res {
            width: 584px;
            .card {
                margin-bottom: 0;
                position: relative;
                box-shadow: none;
                border-bottom: 1px solid $m-dark-25;
                .card-header {
                    padding: 20px 0;
                    background-color: $white;
                    border-bottom-width: 0;
                    @include default_police(16px, normal, 0.4px, left);
                    line-height: 1.19;
                    color: $m-dark;
                    a,
                    a:hover {
                        text-decoration: none;
                    }
                    h2 {
                        line-height: 1;
                    }
                }
                &:first-of-type {
                    .card-header {
                        padding-top: 0;
                    }
                }
                .collapse {
                    .fa-chevron-down {
                        display: block;
                        visibility: visible;
                    }
                    .fa-chevron-up {
                        display: none;
                        visibility: hidden;
                    }
                }
                .collapse.show {
                    .fa-chevron-down {
                        display: none;
                        visibility: hidden;
                    }
                    .fa-chevron-up {
                        display: block;
                        visibility: visible;
                    }
                }
                .card-body {
                    padding-left: 40px;
                    padding-top: 0;
                    background-color: $white;
                    a {
                        margin-top: 10px;
                        margin-bottom: 10px;
                        display: block;
                        @include default_police(16px, 500, 0.4px, left);
                        line-height: 1.25;
                        color: $m-dark;
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
                .open-close-collapse {
                }
            }
            button:not(.msg-btn):not(.sugg-btn) {
                position: relative;
                background-color: $white;
                box-shadow: none;
                margin: 0;
                padding: 0;
                width: 100%;
                .btn-collapse {
                    @include default_police(16px, 500, 0.4px, left);
                    line-height: 1.25;
                    color: $m-dark;
                    flex-direction: row;
                    align-items: center;
                    display: flex;
                    align-content: stretch;
                    .ctn-icon-res {
                        text-align: center;
                        line-height: 30px;
                        display: inline-block;
                        margin-right: 10px;
                        @include circle(30px, $m-dark, inherit);
                        color: $white;
                        flex-grow: 0;
                        flex-shrink: 0;
                    }
                    p {
                        margin-bottom: 0;
                        flex-grow: 1;
                        flex-shrink: 1;
                    }
                }
                &:hover,
                &:focus,
                &:active {
                    text-decoration: none;
                    box-shadow: none;
                }
                &[aria-expanded="true"] {
                    span.open-close-collapse {
                        position: absolute;
                        right: 0;
                    }
                    .btn-collapse {
                        color: $m-button;
                    }
                    .ctn-icon-res {
                        background-color: $m-button;
                    }
                    .fa-chevron-down {
                        visibility: hidden;
                        display: none;
                    }
                    .fa-chevron-up {
                        visibility: visible;
                    }
                }
                &[aria-expanded="false"] {
                    span.open-close-collapse {
                        position: absolute;
                        right: 0;
                        top: calc(50% - 8px);
                    }
                    .fa-chevron-up {
                        visibility: hidden;
                        display: none;
                    }
                    .fa-chevron-down {
                        visibility: visible;
                    }
                }
            }
        }
    }
    .team-act {
        display: flex;
        flex-direction: row;
        align-content: stretch;
        .act-avt {
            margin-right: 10px;
            color: $white;
            flex-grow: 0;
            @include circle(30px, $transparent, inherit);
            flex-shrink: 0;
            @include default_police(18px, bold, 0, center);
            line-height: 30px;
        }
        .act-ct {
            flex-grow: 1;
            flex-shrink: 1;
            div {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                h3 {
                    @include default_police(16px, 500, 0.4px, left);
                    line-height: 1.25;
                    color: $m-primary;
                    margin-bottom: 0;
                }
                .act-date {
                    @include default_police(10px, normal, normal, right);
                    line-height: 1.8;
                    color: $m-dark-50;
                    flex-wrap: nowrap;
                    flex-grow: 0;
                    flex-shrink: 0;
                }
            }
            p {
                @include default_police(14px, normal, normal, left);
                line-height: 1.14;
                color: $m-dark;
            }
        }
    }
}
.sc-lv {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
    margin: 0 auto 15px;
    padding: 0 12px 0 12px;
    .div-sc {
        @include circle(30px, $m-headers, inherit);
        align-self: flex-start;
        @include default_police(25px, bold, 0, center);
        line-height: 30px;
        color: $white;
    }
    .disable {
        opacity: 0.4;
    }
}
.div-pt {
    @include default_police(25px, bold, 1.26px, right);
    line-height: 30px;
    color: $m-button;
    align-self: flex-end;
    span {
        font-size: 14px;
        font-weight: normal;
        line-height: 30px;
    }
}
.div-line {
    padding-left: 6px;
    padding-right: 6px;
    flex-grow: 2;
    hr {
        margin-top: 14px;
        margin-bottom: 15px;
    }
}
//RANKING
.body-rk {
    @include body-page(900px, 50px);
    .row-rk {
        @extend %nv-row;
        justify-content: space-between;
        .col-rk {
            @extend %nv-col;
            width: 337px;
            padding-bottom: 80px;
            .nv-row-rk {
                margin-top: 35px;
                @extend %nv-row;
                width: 337px;
                justify-content: stretch;
                .item-rk {
                    display: flex;
                    flex-direction: column;
                    width: 33.333%;
                    justify-content: space-between;
                    .fst-pl {
                        @include circle(66px, $transparent, relative);
                        margin-left: calc(50% - 33px);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        //border: solid 2px $m-primary;
                        font-size: 32px;
                        font-weight: bold;
                        font-family: Roboto;
                        span {
                            box-shadow: -0 2px 4px 0 $m-dark-50;
                            @include span-si(16px, 16px, $m-bonus);
                            @include default_police(14px, bold, 0, center);
                            line-height: 18px;
                        }
                    }
                    .snd-pl {
                        @include circle(43px, $transparent, relative);
                        margin-top: 23px;
                        bottom: 0;
                        margin-left: calc(50% - 21px);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        //border:solid 2px $m-primary;
                        font-size: 26px;
                        font-weight: bold;
                        font-family: Roboto;
                        span {
                            box-shadow: -0 2px 4px 0 $m-dark-50;
                            @include span-si(16px, 11px, $greyish);
                            @include default_police(14px, bold, 0, center);
                            line-height: 18px;
                        }
                    }
                    .trd-pl {
                        @include circle(43px, $transparent, relative);
                        margin-top: 23px;
                        bottom: 0;
                        margin-left: calc(50% - 21px);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        //border:solid 2px $m-primary;
                        font-size: 26px;
                        font-weight: bold;
                        font-family: Roboto;
                        span {
                            box-shadow: -0 2px 4px 0 $m-dark-50;
                            @include span-si(16px, 11px, $m-headers);
                            @include default_police(14px, bold, 0, center);
                            line-height: 18px;
                        }
                    }
                    .rk-nm {
                        text-transform: capitalize;
                        margin: 10px 0 20px 0;
                        color: $m-dark;
                        @include default_police(16px, 500, 0.4px, center);
                        line-height: 1;
                    }
                    .rk-pt {
                        @include default_police(25px, bold, 2px, center);
                        line-height: 0.5;
                        color: $m-button;
                        span {
                            line-height: 0.5;
                            @include default_police(
                                14px,
                                normal,
                                1.26px,
                                center
                            );
                        }
                    }
                }
                .pos-rk {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    width: 100%;
                    height: 52px;
                    border-radius: 10px;
                    background-color: $m-headers;
                    padding: 0 10px;
                    h4 {
                        @include default_police(18px, bold, 1.44px, left);
                        margin-bottom: 0;
                        color: $white-50;
                        line-height: 1;
                        text-transform: uppercase;
                        padding: 0 26px 0px 6px;
                    }
                    .span-cup {
                        @include default_police(35px, normal, 1.44px, center);
                        line-height: 52px;
                        padding: 0;
                        color: $white-50;
                    }
                    .span-position {
                        @include default_police(46px, bold, 1.38px, center);
                        line-height: 52px;
                        padding: 0;
                        color: $white-50;
                        sup {
                            top: -11px;
                        }
                    }
                }
                .lt-rk-pos,
                .lt-rk-nm {
                    @include default_police(16px, 500, 1.44px, left);
                    color: $m-dark;
                    text-transform: capitalize;
                }
                .lt-rk-nm {
                    width: 220px;
                }
                .lt-rk-avt {
                    margin-left: 18px;
                    margin-right: 10px;
                    display: block;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    @include circle(30px, $transparent, relative);
                }
                .lt-rk-sc {
                    align-self: flex-end;
                    @include default_police(19px, bold, 0.48px, center);
                    color: $m-button;
                    line-height: 0.58;
                    width: 44px;
                }
                .lt-rk-pt {
                    @include default_police(14px, normal, 0.48px, center);
                }
            }
        }
    }
}
.md-sc-avg {
    overflow: visible;
    box-shadow: 0 12px 17px 0 $m-dark-25;
    flex-direction: column;
    font-family: Roboto;
    color: $white;
    left: calc(50% - 50px);
    bottom: -50px;
    @include circle(100px, $m-button, absolute);
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 0.6;
    b {
        font-size: 25px;
        font-weight: 700;
    }
    span.label {
        text-transform: uppercase;
        line-height: 1.21;
        color: $white;
        @include default_police(14px, normal, 1.26px, center);
    }
    span.label.avg {
        font-size: 11px;
        padding: 0 3px 6px 3px;
    }
}
//END RANKING
.bg-light-top-half {
    z-index: 1;
    background-image: url("../images/wave-light-top-half.svg");
}
.bg-primary-top-half {
    background-image: url("../images/wave-primary-top-half.svg");
}
.bg-bot-primary {
    background-image: url("../images/wave-primary-bot.svg");
}
.bg-bot-primary-half {
    background-image: url("../images/wave-primary-bot-half.svg");
}
.bg-light-bot {
    background-image: url("../images/wave-light-bot.svg");
}
.bg-light-bot-half {
    background-image: url("../images/wave-light-bot-half.svg");
}
.bg-white-bot {
    background-image: url("../images/wave-white-bot.svg");
}
.bg-white-bot-half {
    background-image: url("../images/wave-white-bot-half.svg");
}
//MODAL resources
.modal-content {
    .modal-header {
        border-bottom: 0px solid $white;
        text-align: center;
        align-items: center;
        justify-content: center;
        .modal-ttl {
            font-size: 16px;
            text-align: center;
            font-family: Roboto;
            border-bottom: 2px solid $m-bonus;
            font-weight: 500;
            display: block;
        }
        .close {
            margin: 0 0 0 0;
            box-sizing: border-box;
            position: absolute;
            padding: 5px 10px;
            top: 0;
            right: 0;
        }
        .close,
        .close:hover,
        .close:not(:disabled):not(.disabled):focus,
        .close:not(:disabled):not(.disabled):hover {
            color: $m-errors;
        }
    }
    .modal-body {
        font-family: "Roboto", serif;
        color: $m-dark;
        @include default_police(14px, normal, normal, left);
        line-height: 1.29;
    }
}
.modal-dialog {
    .modal-content {
        border-radius: 10px;
    }
}
.modal.md-lv {
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    background-color: $blur-bg;
    .modal-dialog {
        width: 500px;
        margin: auto;
    }
    .modal-body {
        overflow: hidden;
        width: 100%;
        padding-bottom: 55px;
        .card-nv {
            a {
                align-items: center;
                span {
                    margin-top: 0;
                    margin-bottom: 0;
                    margin-left: 0;
                }
            }
            .item {
                display: flex;
                align-items: center;
                flex-direction: row;
                margin-bottom: 12px;
                font-size: 16px;
            }
        }
        .pts-qt {
            width: 34px;
            flex-grow: 0;
            flex-shrink: 0;
            background-color: $white;
            @include default_police(14px, normal, 0, center);
            color: $m-primary;
            line-height: 0.5;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            b {
                font-size: 19px;
            }
        }
        .pts-qt.correct {
            color: $m-button;
        }
        .pts-qt.wrong {
            color: $m-errors;
        }
        .icon-aw {
            left: 67px;
        }
        .line-btw {
            margin-left: 59px;
        }
    }
    .md-sc-lv {
        overflow: visible;
        box-shadow: 0 12px 17px 0 $m-dark-25;
        flex-direction: column;
        font-family: Roboto;
        color: $white;
        left: calc(50% - 35px);
        bottom: -35px;
        @include circle(70px, $m-button, absolute);
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        @include default_police(14px, normal, 0.35px, center);
        line-height: 0.6;
        b {
            font-size: 25px;
            margin-bottom: 3px;
        }
    }
    .modal-content.md-ct-lv {
        border-radius: 10px;
        font-family: Roboto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: 0 12px 17px 0 $m-dark-25;
        .modal-header {
            padding: 22px 12px;
            h2 {
                margin-bottom: 0;
            }
        }
        .md-lv-nb {
            @include circle(50px, $m-button, inherit);
            margin-top: -25px;
            display: flex;
            text-align: center;
            justify-content: center;
            align-items: center;
            @include default_police(35px, bold, normal, center);
            line-height: 1.2;
            color: $white;
        }
        .modal-ttl {
            @include default_police(18px, bold, 1.44px, center);
            line-height: 1.11;
            color: $m-primary;
            text-transform: uppercase;
            border-bottom-width: 0;
        }
    }
}
/*Helper Class*/
.mb-0 {
    margin-bottom: 0;
}
.mt30 {
    margin-top: 30px;
}
.mb30 {
    margin-bottom: 30px;
}
.ml30 {
    margin-left: 30px;
}
@include circle-list;
.ccl {
    z-index: 0;
    margin-top: 20px;
}
.ccl1 {
    @include circle(154px, $m-secondary, static);
}
.ccl2 {
    @include circle(121px, $m-secondary, static);
}
.ccl3 {
    @include circle(174px, $m-secondary, static);
}
.ccl-dist-1 {
    margin-left: -20px;
}
.ccl-dist-20 {
    margin-left: 98%;
}
.even {
    background-color: $m-secondary;
}
.even.selected {
    background-color: $m-secondary;
}
.odd {
    background-color: $m-primary;
}
.odd.selected {
    background-color: $m-primary;
}
@each $name, $col in $colors-nv {
    .bg-#{$name} {
        background-color: $col;
    }
}
u {
    position: relative;
    text-decoration: none;
    -webkit-text-decoration: none;
    z-index: 1;
    span.underline {
        position: absolute;
        width: 100%;
        background-color: $m-secondary;
        height: 20px;
        bottom: 0;
        z-index: -1;
        right: 0;
    }
}
.fa-edit {
    font-size: 16px;
}
.fa-file-pdf {
    font-size: 18px;
    color: white;
}
.fa-file-medical {
    font-size: 16px;
}
.curve-lv {
    position: absolute;
    z-index: -1;
}
.curve-lv-0 {
    top: 4%;
    left: 190px;
}
.curve-lv-1 {
    top: 100px;
    left: 120px;
}
.curve-lv-2 {
    top: 0;
    left: 190px;
}
.curve-lv-3 {
    top: 0;
    left: 140px;
}
.hp-pd-bt-0 {
    padding-bottom: 0 !important;
}
.hp-mg-tp-20 {
    margin-top: 20px;
}
.hp-ag-rg {
    align-self: flex-end;
}
.dropright .dropdown-toggle:after {
    border-width: 0;
}
.dropdown-menu {
    margin: 0;
    padding: 0;
    border-radius: 10px;
    border-width: 0;
    width: 325px;
    box-shadow: 0 0px 18px 0 $m-dark-25;
}
.dd-ttl {
    padding: 12px 12px;
    margin-bottom: 5px;
    background-color: $m-light;
    white-space: nowrap;
    @include default_police(16px, 500, normal, left);
    color: $m-primary;
    text-transform: uppercase;
    padding-right: 94px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.dd-ttl.all-borders {
    border-radius: 10px;
    margin-bottom: 0px;
}
.dd-item {
    padding: 5px 10px 10px 10px;
    white-space: nowrap;
    @include default_police(16px, 500, normal, left);
    justify-content: stretch;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    color: $m-dark;
    &:last-of-type {
        border-bottom-width: 0;
    }
    &:first-of-type {
        padding-top: 0;
    }
    .txt {
        flex-grow: 1;
        flex-shrink: 1;
        white-space: normal;
        line-height: initial;
    }
}
.dd-pt {
    @include default_police(19px, bold, 0, center);
    line-height: 0.6;
    width: 44px;
    color: $m-button;
    margin-left: 15px;
    flex-grow: 0;
    flex-shrink: 0;
    span {
        font-size: 14px;
        font-weight: normal;
        text-align: center;
        text-transform: lowercase;
    }
}
.dd-badge {
    color: $m-button;
    width: 30px;
    display: flex;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: center;
}
.dd-badge-txt {
    color: $m-primary;
    width: 85px;
    display: block;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: flex-start;
    line-height: 1;
    font-size: 12px;
    text-transform: uppercase;
    sup {
        text-transform: lowercase;
    }
}
.sc-hm-lv {
    @include circle(88px, $m-headers, absolute);
    color: $white;
    top: -58px;
    right: 12px;
    box-shadow: 0px 0px 10px -6px $m-dark;
    @include default_police(25px, normal, normal, center);
    line-height: 1;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    span.label {
        font-size: 14px;
        letter-spacing: 1.26px;
        line-height: 1.21;
    }
    span.label.avg {
        font-size: 10px;
        padding: 0 3px 0px 3px;
    }
}
.bn-div {
    border-top-color: $m-light;
    border-top-width: 1px;
    margin: 0;
}
.bk-div {
    cursor: pointer;
    position: fixed;
    height: 68px;
    width: 20px;
    top: calc(50vh - 34px);
    left: 0;
    background-color: $m-primary;
    transition: width 200ms ease-in-out;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    color: $white;
    z-index: 1040;
    a {
        display: block;
        height: 68px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        overflow: hidden;
        color: $white;
        &:hover,
        &:visited,
        &:focus,
        &:active {
            text-decoration: none;
            color: inherit;
        }
    }
    span {
        font-size: 24px;
        font-weight: normal;
        margin-left: 2px;
    }
    p {
        text-transform: uppercase;
        font-weight: 500;
        text-align: center;
        margin-left: 10px;
        margin-bottom: 0;
        line-height: 1;
        white-space: nowrap;
    }
    &:hover {
        width: 118px;
        p {
        }
    }
}
.qt-ctn-bn {
    border-radius: 8px;
    background-color: $white;
    padding-left: 9px;
    padding-right: 9px;
    margin: 0;
}
.st-avt {
    @include circle(99px, $m-primary, inherit);
    display: flex;
    border: solid 2px $m-primary;
    justify-content: center;
    align-items: center;
    font-size: 45px;
    text-align: center;
}
.body-st {
    width: 534px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 30px auto;
    .st-ttl {
        display: flex;
        justify-content: center;
        text-align: center;
        margin-bottom: 20px;
        h2 {
            @include default_police(16px, 500, 0.4px, center);
            line-height: 19px;
            color: $m-primary;
            border-bottom: 2px solid $m-bonus;
        }
    }
    .btn-lng {
        width: 100%;
        background-color: none;
        text-transform: uppercase;
        color: $m-dark-25;
        @include default_police(14px, 500, normal, center);
        border-radius: 10px;
        border: 1px solid $m-dark-25;
        line-height: 30px;
        margin: 8px 0;
        cursor: pointer;
    }
    .btn-lng.selected {
        width: 100%;
        background-color: $m-primary;
        text-transform: uppercase;
        color: $white;
        @include default_police(14px, 500, normal, normal);
        border-radius: 10px;
        cursor: default;
        box-shadow: 0 1px 3px 0 $m-dark-25;
        &:hover {
            box-shadow: 0 0 0 0 $m-dark-25;
        }
    }
    .row-av-st {
        width: 160px;
        display: flex;
        flex-direction: column;
        .av-st {
            position: relative;
            display: flex;
            justify-content: center;
            flex-direction: column;
            margin-bottom: 11px;
            .av-img-st {
                @include circle(115px, $m-primary, inherit);
                border: 2px solid $m-primary;
                margin: auto;
            }
            .av-icon-st {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 22px;
                @include circle(39px, $m-button, absolute);
                color: $white;
                bottom: 0;
                right: 12px;
                cursor: pointer;
                box-shadow: 0 1px 3px 0 $m-button;
                &:hover {
                    box-shadow: unset;
                }
                label {
                    margin-bottom: 0;
                    cursor: pointer;
                }
            }
        }
        .lng-st {
            display: flex;
            flex-direction: column;
            padding-top: 20px;
            padding-bottom: 20px;
            justify-content: space-between;
        }
    }
    .row-psw-st {
        width: 298px;
    }
    .md-form {
        width: 100%;
    }
}

.btn-st {
    width: 160px;
    height: 40px;
    @include default_police(14px, 500, normal, center);
    border-radius: 10px;
    margin: 30px auto;
    text-transform: uppercase;
    cursor: pointer;
    line-height: 40px;
    box-shadow: 0 1px 3px 0 $m-button;
    background-color: $m-button;
    &:hover {
        box-shadow: 0 0 0 0 $m-button;
    }
}
.field-icon {
    position: absolute;
    display: inline-block;
    cursor: pointer;
    right: 0.5rem;
    top: 0.7rem;
    color: $m-dark;
    z-index: 2;
}
.list-psw {
    color: $m-dark-75;
    list-style: none;
    padding: 0;
    @include default_police(16px, 400, normal, left);
    svg,
    i {
        color: $m-dark-25;
        margin-right: 10px;
        font-size: 12px;
    }
    li.li-psw.active {
        i,
        svg {
            color: $m-headers;
        }
    }
}
.ctn-rs {
    width: 297px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    min-height: 100vh;
    align-items: center;
    h1 {
        @include default_police(18px, bold, 0.4px, center);
        line-height: 1.11;
        color: $m-primary;
        text-transform: uppercase;
    }
    p {
        @include default_police(16px, 500, 1.25px, center);
        line-height: 1.25;
        color: $m-primary;
    }
    .btn-reset {
        @extend %btn-base;
        @include default_police(14px, 500, normal, center);
        padding: 0;
        margin: 30px 0 0 0;
        align-self: center;
        &:hover {
            box-shadow: 0 0 0 0 $m-button;
        }
    }
    form {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
}
.ctn-img {
    width: 178px;
    margin: 30px auto;
}
.curve-lv-mb {
    display: none;
}
.card-body {
    padding-right: 0;
    div {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: $m-dark;
        align-items: center;
        span {
            font-size: 20px;
            width: 30px;
            text-align: right;
        }
    }
}
.modal-backdrop {
    background-color: $transparent;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    &.show {
        opacity: 1;
    }
}
.open-gsc,
.open-psc {
    cursor: default;
}
.dw-rs {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding: 0 1rem 1rem 1rem;
    flex-direction: row;
    font-size: 25px;
    color: $m-button;
}
.new-jk {
    position: relative;
    height: 206px;
    div:nth-child(1) {
        top: 0;
    }
    div:nth-child(2) {
        top: 55px;
    }
    div:nth-child(3) {
        top: 110px;
    }
    div:nth-child(4) {
        top: 165px;
    }
    div:nth-child(5) {
        top: 220px;
    }
    div:nth-child(6) {
        top: 275px;
    }
}
.ppal-jk {
    display: flex;
    flex-direction: row;
    align-content: stretch;
    align-items: center;
    justify-content: flex-end;
    height: 41px;
    flex-grow: 0;
    flex-shrink: 0;
    h3 {
    }
}
.ds-jk {
    height: 180px;
    overflow: hidden;
    transition: height 0.4s ease-in-out;
}
.jk-item.jk-item-js.active {
    position: absolute;
    height: 206px;
    align-self: stretch;
    top: 0;
    width: 100%;
    display: flex;
    z-index: 1018;
}
.jk-btns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .rf-jk {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $m-button;
        font-weight: 500;
        width: 160px;
    }
    .cf-jk {
        @extend %btn-base;
        font-weight: 500;
        &:hover {
            box-shadow: unset;
        }
    }
}
.help-hg {
    min-height: calc(100vh - 130px);
}
.modal-dialog .modal-content .modal-header {
    padding-top: 28px;
}
.lv-info {
    .dropdown-toggle:after {
        display: none;
        content: "";
    }
}
.lv-info {
    .lv-res.dropdown-toggle.show,
    .lv-group.dropdown-toggle.show {
        div.lv-icon.dropdown-toggle > i {
            color: $m-primary;
        }
    }
}

// Additional Adjustment
.body-pf {
    width: 1080px;
    .row-pf {
        .pf-sc {
            margin: 0 126px;
        }
        .pf-pro {
            margin: 0 auto;
        }
        .pf-res {
            margin: 0 auto;
        }
    }
}
.pf-sc-by-lv {
    max-width: 380px;
}
// certainty check
.btn-doubt-qt {
    width: 160px;
    height: auto;
    float: right;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    color: $m-primary;
    div {
        display: flex;
        justify-content: space-between;
        margin: 5px 0 25px 0;
        .face-pos,
        .face-neg {
            font-size: 40px;
            font-weight: 300;
            cursor: pointer;
            &.selected::before {
                font-weight: 900;
            }
        }
        .face-pos {
            color: $m-headers;
        }
        .face-neut {
            vertical-align: top;
            cursor: pointer;
        }
        .face-neg {
            color: $m-button;
        }
    }
}
.btn-submit-qt {
    clear: right;
}
// extend curve imgs
@media screen and (min-width: 1920px) {
    .footer-curve,
    .footer-curve-relative,
    .mask-curve-bot,
    .mask-curve-bot-footer,
    .mask-curve-bot-footer-relative,
    .mask-curve-top {
        background-size: contain;
    }
    .mask-curve-bot-footer-relative {
        //bottom: -2px;
    }
}
// Notification
.bell-btn {
    cursor: pointer;
    padding-right: 30px;
    color: $m-primary;
    box-sizing: border-box;
    padding-top: 12px;
    width: $nav-heigth-fixed;
    height: $nav-heigth-fixed;
    @include default_police(24px, normal, normal, right);
    line-height: 0;
    &:hover {
        color: $m-primary-80;
    }
    &.unread .fa-bell {
        position: relative;
        &:after {
            content: "";
            color: $white;
            text-align: center;
            position: absolute;
            background: $m-errors;
            height: 0.4em;
            width: 0.4em;
            right: -2px;
            border-radius: 50%;
            border: 1px solid $m-errors;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.3), 0 0 3px rgba(0, 0, 0, 0.45);
        }
    }
}
#notif-dropdown {
    top: 60px;
    right: 0px;
    left: unset;
    width: 400px;
    box-shadow: 0px 12px 17px rgba(51, 51, 51, 0.16);
    padding-bottom: 0px;
    padding: 0px;
    // &:before {
    //   content: "";
    //   position: absolute;
    //   top: -20px;
    //   right: 42px;
    //   border: 10px solid $m-primary;
    //   border-color: transparent transparent $m-primary transparent;
    // }
    &.show {
        left: -22px !important;
    }
    .row {
        margin-bottom: 0;
    }
    .head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 15px;
        border-radius: 5px 5px 0px 0px;
        background-color: $m-headers;
        color: $white;
        a {
            color: $m-primary;
            font-size: 12px;
            font-weight: 400;
        }
        span {
            text-transform: uppercase;
            font-weight: 500;
            font-size: 16px;
        }
        a:hover {
            text-decoration: none;
        }
    }
    .footer {
        padding: 5px 15px;
        border-radius: 0px 0px 3px 3px;
        // background-color: $m-primary;
        a {
            color: $m-primary;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    .notif-dropdown-content {
        max-height: 200px;
        overflow-x: hidden;
        /* Track */
        &::-webkit-scrollbar-track {
            background: $m-light;
        }
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: $m-secondary;
        }
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: $m-secondary;
        }
        /* width + height */
        &::-webkit-scrollbar {
            width: 4px;
            height: 4px;
        }
        height: calc(100vh - 68px);
        overflow-y: auto;
        .empty-notif {
            width: 100%;
            height: calc(100vh - 68px);
            max-height: 200px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $m-primary;
            font-family: "Roboto";
            font-size: 16px;
            font-weight: 500;
        }
    }
    .notification-box {
        display: flex;
        align-content: stretch;
        flex-direction: row;
        align-items: flex-start;
        padding: 5px 15px;
        &:nth-child(2n + 1) {
            background-color: rgba(51, 51, 51, 0.03);
        }
        &.unread {
        }
        .text-name {
            color: $m-primary;
        }
        .text-time {
            color: $m-button;
        }
        .remove-btn {
            &:hover {
                cursor: pointer;
                text-decoration: none;
            }
        }
        .notif-content {
            display: flex;
            flex-grow: 1;
            flex-direction: column;
            div {
                display: flex;
                justify-content: space-between;
            }
            .notif-name {
                font-size: 16px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: 0.4px;
                text-align: left;
                line-height: 1.25;
                color: $m-primary;
                margin-bottom: 0;
            }
            .notif-date {
                font-size: 10px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: normal;
                text-align: right;
                color: $m-dark-50;
                flex-wrap: nowrap;
                flex-grow: 0;
                flex-shrink: 0;
                padding-right: 5px;
            }
            .notif-content-txt {
                padding-right: 5px;
                font-size: 14px;
                font-weight: 400;
                color: $m-dark;
            }
        }
        .notif-remove {
            width: 30px;
            flex-grow: 0;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            align-self: center;
            color: $m-dark-50;
            font-size: 21px;
        }
        .sc-avt {
            flex-shrink: 0;
            flex-grow: 0;
        }
    }
}
#tuto_modal {
    background-color: $m-primary-50;
}
.modal-dialog.modal-tuto {
    width: 800px;
    max-width: 800px;
    .tuto_close {
        font-weight: 400;
        font-size: 16px;
        font-family: "Roboto";
        color: $m-dark-75;
        margin: 0 0 0 0;
        box-sizing: border-box;
        position: absolute;
        padding: 5px 10px;
        top: 0;
        right: 0;
        background-color: transparent;
        text-transform: capitalize;
        -webkit-appearance: none;
        -moz-appearance: none;
        box-shadow: none;
        z-index: 5;
    }
    .tuto_close_mb {
        display: none;
        visibility: hidden;
    }
    .carousel {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .ctn-indicators {
        display: flex;
        justify-content: space-between;
        padding: 20px 55px 4px 55px;
        align-items: center;
        ol.carousel-indicators {
            position: relative;
            right: unset;
            bottom: unset;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 0;
            li {
                height: 2px;
                width: 53px;
                margin-top: 0px;
                background-color: $m-dark-50;
                margin-right: 15px;
                border-radius: 0;
                &.active {
                    height: 6px;
                    background-color: $m-button;
                }
            }
        }
        .btn-next {
            cursor: pointer;
            width: 160px;
            height: 40px;
            border-radius: 10px;
            box-shadow: 0 1px 3px 0 $m-button;
            background-color: $m-button;
            &:hover {
                background-color: $m-button-50;
            }
            a {
                align-self: flex-end;
                text-transform: uppercase;
                display: block;
                line-height: 40px;
                color: #fff;
                font-size: 14px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: 1.26px;
                text-align: center;
                &:hover {
                    text-decoration: none;
                    transition: all 0.2s ease-in-out;
                }
            }
        }
    }
    .modal-content {
        background-position: top right;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url("../images/bg-tuto.svg");
    }
    .carousel-item {
        min-height: 385px;
    }
    .modal-body {
        padding: 0;
    }
    .ctn-tuto-txt {
        display: flex;
        width: 800px;
        padding: 40px 55px 0 55px;
        justify-content: space-between;
        &.npright {
            padding-right: 0;
        }
        .ctn-txt {
            width: 256px;
        }
        .ctn-img {
            width: auto;
            margin: 0;
            img.img-mb {
                display: none;
                visibility: hidden;
            }
        }
        div {
            h2 {
                font-weight: 500;
                font-size: 34px;
                font-family: "Roboto";
                color: $m-primary;
                margin-bottom: 20px;
            }
            p {
                font-weight: 400;
                font-size: 16px;
                font-family: "Roboto";
                color: $m-dark;
            }
        }
        &.helper {
            padding-right: 0;
        }
    }
}
// add css to make youtube embed video responsive
.qt-container {
    display: flex;
    flex-direction: row;
    .qt-wrapper {
        flex-direction: column;
        flex: 1;
    }
    .qt-text {
        @include default_police(16px, 500, 0.4px, left);
        line-height: 1.25;
        color: $m-dark;
        padding-top: 0.5em;
    }
    video {
        margin-bottom: 1em;
    }
    .youtube-wrapper {
        margin-bottom: 1em;
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        height: 0;
        overflow: hidden;
        iframe, object, embed {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}
// Language buttons
.lk-lang {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-top: 23px; // 30px;
    margin-bottom: 20px;
    .en, .fr {
        text-transform: uppercase;
        position: relative;
        height: 14px;
        @include default_police(12px, 500, 1.08px, center);
        line-height: 1.17;
        color: $m-button;
        &:visited,
        &:active,
        &:focus,
        &:hover {
            color: $m-button;
        }
    }
}

@import "media901_1400";
@import "media1200";
@import "media900";
@import "media750";
@import "media400";
@import "media345";
