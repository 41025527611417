@media screen and (max-width: 345px) {
  .ctn-lg-ct, .ctn-lg-ct.hg750, .lg-form { width: 320px; }
  .ctn-lg-form {width:320px;}
  .md-form input[type="email"]+label,
  .md-form input[type="password"]+label {width:inherit;}
  .ctn-lg-ct .helper-ctn-lg {height:500px;}
  .ppal-nav { top:-120vh; }
  .welcome-user {
    .text-fixed,.text-nm { font-size:26px; }
  }
  .msg-box {
    padding:60px 17px 0 17px;
    .msg-text {
      font-size:20px;
      u {span.underline { height:1vh; }}
    }
  }
  .ctn-intro {
    max-height:100%;
    .intro-text{margin-top: 0}
  }
  .ctn-lv-item {
    width:318px;
    .lv-number {}
    .lv-img { margin-left:9px;width:300px;height:212px; }
    .lv-mask { left:10px;width:300px;height:212px; }
    .lv-ctn-ttl {
      z-index:-1;margin-top:-140px;
      .lv-ttl {
        width:320px;padding-left:10px;padding-right:10px;padding-bottom:12px;
        h2 { }
      }
      .lv-info {
        padding-left:10px;padding-top:7px;height:62px;width:318px;
        .lv-group, .lv-res {
          width:48px;height:48px;
          .lv-icon { }
          .lv-counter-g, .lv-counter-r { top:5px;z-index:5;font-size:9px; }
          .lv-counter-g { right:3px; }
          .lv-counter-r { right:6px; }
        }
        .lv-btn,.lv-btn-dd {
          right:14px;top:10px;
          a { }
        }
        .lv-btn:hover, .lv-btn-dd { }
        .lv-mask-btn { right:14px;top:10px; }
      }
    }
  }
  .body-pf {
    .row-pf {
      .pf-pro {
        .lv-pf-ctn { transform: scale(0.75);}
      }
      .pf-res {
        padding-left:10px;padding-right:10px;

      }
    }
  }
  .body-rk {
    .row-rk {
      .col-rk {
        width:312px;margin-left:auto;margin-right:auto;
        .nv-row-rk {
          width:100%;margin-left:0;margin-right:0;
          .pos-rk {
            padding-left:2px;padding-right:2px;
          h4 {
            padding: 0 2px;
          }
        }
          .lt-rk-avt {flex-grow:0;flex-shrink:0;}
        }
      }
    }
  }
  .md-ct-lv { transform: scale(0.85); }
}
