@media screen and (max-width: 900px) {
    /*#team_score, .open-psc.without_records, .txt-sc.without_records {
    display: none;
  }*/
    .ctn-lv-hm {
        width: 100%;
        padding-top: 116px;
        padding-bottom: 0;
    }
    .ctn-intro {
        position: relative;
        box-shadow: 0 0 0 0 $m-dark-25;
        justify-content: space-between;
        flex-direction: column;
        top: 0;
        left: 0;
        transform: unset;
        padding: 17px;
        width: 100%;
        height: 100%;
        border-radius: 0;
        background-color: unset;
        display: flex;
        margin: auto;
        padding-top: 45px;
        max-height: 100%;
        .intro-text {
            margin-bottom: 20px;
            p {
                overflow: auto;
                font-family: "Roboto", serif;
                line-height: 1.29;
                @include default_police(14px, normal, normal, left);
                color: $m-dark;
                overflow: hidden;
                max-height: 100vh;
            }
        }
    }
    .welcome-user {
        z-index: 5;
        position: relative;
        left: unset;
        top: unset;
        margin-left: unset;
        width: 100%;
        max-width: 600px;
        margin: auto;
        padding: 35px 17px 0 17px;
        .text-fixed,
        .text-nm {
            font-size: 42px;
        }
    }
    .msg-box {
        position: relative;
        top: unset;
        right: unset;
        left: unset;
        width: 100%;
        max-width: 600px;
        margin: auto;
        padding: 100px 17px 0 17px;
        .msg-text {
            font-size: 32px;
            u {
                span.underline {
                    height: 1.5vh;
                }
            }
        }
    }
    .ctn-gm-hm {
        min-height: 1px;
        padding-bottom: 40px;
        .header-order {
            width: 50px;
            height: 50px;
            font-size: 35px;
            left: calc(50% - 25px);
            line-height: 50px;
        }
        .bg-hm {
            display: none;
        }
        .bg-hm.without_records {
            display: block;
            position: relative;
            width: 100%;
            margin-top: 0;
            height: calc(100vw * 0.66);
        }
        .ctn-intro-lv {
            position: relative;
            width: 100%;
            max-width: 100vw;
            left: 0;
            height: 100%;
            top: 0;
            padding: 30px 0 0 0;
            p {
                display: block;
                padding: 26px;
                background-color: $white;
                margin: 0;
                color: $black;
            }
        }
    }
    .ctn-qt-hm {
        .ctn-ct-qt {
            padding: 180px 26px 20px 26px;
            z-index: 3;
            h2 {
            }
        }
        .ctn-jk {
            padding: 180px 26px 0 26px;
        }
        h1 {
            z-index: 2;
            padding-right: 60px;
        }
        .jk-box {
            position: relative;
            z-index: 2;
        }
    }
    .snd-lv,
    .fst-lv {
        justify-content: center;
        margin-bottom: 157px;
    }
    .curve-lv {
        display: none;
    }
    .curve-lv-mb {
        display: block;
        position: absolute;
        top: 100%;
        z-index: -1;
        left: calc(50% - 22px);
    }
    .ctn-ccl {
        position: absolute;
        flex-direction: column;
        justify-content: space-between;
        display: flex;
    }
    .ccl-0 {
        top: 400px;
        left: calc(50% - 40px);
        right: unset;
    }
    .ccl-1 {
        top: 950px;
        left: calc(50% + 155px);
        right: unset;
    }
    .ccl-2 {
        top: 1500px;
        left: calc(50% - 250px);
        right: unset;
    }
    .ccl-3 {
        top: 2050px;
        left: calc(50% + 30px);
        right: unset;
    }
    .ccl-4 {
        top: 2600px;
        left: calc(50% - 220px);
        right: unset;
    }
    .ccl-5 {
        top: 3050px;
        left: calc(50% + 75px);
        right: unset;
    }
    .ccl-6 {
        top: 3550px;
        left: unset;
        right: 0;
    }
    .ccl-last {
        bottom: -56px;
        left: calc(50% - 120px);
        z-index: -1;
    }
    .md-hidden {
        display: none !important;
    }
    @each $name, $col in $colors-nv {
        .md-bg-#{$name} {
            background-color: $col;
        }
    }
    .mask-curve-top.bg-top-f5 {
        background-position: top;
        background-size: contain;
        z-index: 1;
    }
    .mask-curve-top.bg-top-twilight {
        z-index: -1;
    }
    .ctn-res {
        padding: 25px 26px;
        .res-ct {
            a {
                width: 33%;
            }
        }
    }
    .body-pf {
        width: 100%;
        .row-pf {
            flex-direction: column;
            align-items: center;
            width: 100%;
            .pf-pro {
                padding-top: 40px;
                width: 100%;
                .lv-pf-ctn {
                    width: 585px;
                    margin: auto;
                    margin-top: 35px;
                }
            }
            .pf-res {
                width: 100%;
                max-width: 585px;
                margin: auto;
                margin-top: 70px;
                button[aria-expanded="false"] span.open-close-collapse,
                button[aria-expanded="true"] span.open-close-collapse {
                    position: relative;
                }
            }
        }
    }
    .body-rk {
        width: 740px;
    }
    .header-pf {
        z-index: 3;
    }
    .pf-sc {
    }
    .md-hidden {
        display: none !important;
    }
    .pf-sc-by-lv {
        width: 100%;
        max-width: 585px;
    }

    .modal-dialog.modal-tuto {
        margin: auto;
        width: 100vw;
        max-width: 720px;
        .ctn-tuto-txt {
            width: 720px;
        }
    }
}
