@media screen and (max-width: 400px) {
    .ctn-lg-ct.hg750 {
        min-height: 750px;
    }
    .lg-mask-top.hg750,
    .lg-mask-bot.hg750 {
        height: 375px;
    }
    .lg-mask-top.hide,
    .lg-mask-bot.hide,
    .lg-mask-top.hg750.hide,
    .lg-mask-bot.hg750.hide {
        height: 0;
    }
    .bg-bot-primary {
        background-image: url("../images/wave-primary-bot.svg");
    }
    .bg-bot-primary-half {
        background-image: url("../images/wave-primary-bot-half.svg");
    }
    .sm-hidden {
        display: none !important;
    }
    .btn-back {
        display: block;
        visibility: visible;
        padding-left: 20px;
        a {
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 51px;
            letter-spacing: 1.43px;
            text-align: center;
            color: $m-primary;
            text-decoration: none;
        }
        a:active,
        a:hover,
        a:visited,
        a:focus {
            color: $m-primary;
            text-decoration: none;
        }
    }
    .ctn-res {
        flex-direction: column;
        .res-ct {
            flex-direction: column;
            padding-top: 20px;
            a {
                width: 100%;
                justify-content: flex-start;
                margin-bottom: 15px;
            }
        }
    }
    .ctn-qt-hm {
        padding-top: 0;
        .order-qt {
        }
        h1 {
        }
    }
    .ctn-nav-ppal {
        .ctn-ppal-nav {
            padding-left: 17px;
            padding-right: 17px;
            .menu-item {
                @include default_police(14px, 500, 1.62px, right);
                display: flex;
                line-height: 22px;
                color: $white;
                align-items: center;
                width: 100%;
                height: 52px;
                padding: 0;
                .ctn-sc {
                    display: flex;
                    visibility: visible;
                    align-self: flex-start;
                    margin: 0;
                    align-items: center;
                    justify-content: flex-start;
                    .open-gsc {
                        margin: 0 10px 0 0;
                    }
                    .txt-sc {
                        color: $white;
                    }
                }
                a {
                    display: block;
                    color: $white;
                    width: 100%;
                }
            }
            .menu-item-settings {
                text-align: right;
                flex-direction: column;
                align-items: flex-end;
                padding-top: 0;
                height: 90px;
                width: 100%;
                a {
                    padding-top: 12px;
                    padding-bottom: 12px;
                    display: block;
                    color: $white-25;
                }
                button.btn-modal-menu {
                    padding-bottom: 10px;
                    padding-top: 12px;
                    cursor: pointer;
                    font-family: "Roboto";
                    font-size: 12px;
                    text-transform: uppercase;
                    font-weight: 500;
                    background-color: transparent;
                    border: none;
                    color: $white-25;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    letter-spacing: 1.62px;
                    &:focus {
                        outline: none;
                    }
                }
            }
        }
    }
    .close-menu {
        padding-right: 15px;
    }
    .ctn-sc {
        margin-left: 17px;
    }
    .burguer-btn,
    .bell-btn {
        padding-right: 17px;
    }
    .dropdown-menu.show {
        min-width: 325px;
    }
    .dropright {
        .dropdown-menu {
            margin-left: 12px;
        }
    }
    #notif-dropdown.show {
        left: 0px !important;
        transform: translate3d(18px, 56px, 0px) !important;
    }
    #notif-dropdown {
        width: 342px;
    }
    .modal-dialog.modal-tuto {
        margin: auto;
        width: 100vw;
        max-width: 375px;
        min-height: 100vh;
        .tuto_close {
            font-weight: 400;
            font-size: 16px;
            font-family: "Roboto";
            color: $m-dark-75;
            margin: 0 0 0 0;
            box-sizing: border-box;
            position: absolute;
            padding: 5px 10px;
            top: unset;
            right: unset;
            left: 0;
            bottom: 30px;
            height: 40px;
            background-color: transparent;
            text-transform: capitalize;
            -webkit-appearance: none;
            -moz-appearance: none;
            box-shadow: none;
            z-index: 5;
        }
        .carousel {
            padding-top: 0;
            padding-bottom: 0;
        }
        .ctn-indicators {
            display: flex;
            justify-content: space-between;
            padding: 20px 16px 20px 16px;
            align-items: center;
            flex-direction: column;
            ol.carousel-indicators {
                position: relative;
                right: unset;
                bottom: unset;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-left: 0;
                margin-right: 0;
                margin-bottom: 0;
                li {
                    height: 2px;
                    width: 53px;
                    margin-top: 0px;
                    background-color: $m-dark-50;
                    margin-right: 15px;
                    border-radius: 0;
                    &.active {
                        height: 6px;
                        background-color: $m-button;
                    }
                }
            }
            .btn-next {
                align-self: flex-end;
                margin-top: 30px;
            }
        }
        .modal-content {
            border-radius: 0;
            min-height: 100vh;
            background-image: url("../images/bg-tuto-mb.svg");
        }
        .carousel-item {
            min-height: 385px;
        }
        .modal-body {
            padding: 0;
        }
        .ctn-tuto-txt {
            display: flex;
            width: 100%;
            padding: 55px 16px 0 16px;
            justify-content: space-between;
            flex-direction: column-reverse;
            &.npright {
                padding-right: 16px;
                padding-top: 0;
            }
            .ctn-txt {
                width: 100%;
            }
            .ctn-img {
                width: auto;
                margin: 0;
                display: flex;
                justify-content: center;
                img.img-mb {
                    display: block;
                    visibility: visible;
                }
                img.img-dk {
                    display: none;
                    visibility: hidden;
                }
            }
            div {
                h2 {
                    margin-top: 30px;
                    font-weight: 500;
                    font-size: 34px;
                    font-family: "Roboto";
                    color: $m-primary;
                    margin-bottom: 20px;
                }
                p {
                    font-weight: 400;
                    font-size: 16px;
                    font-family: "Roboto";
                    color: $m-dark;
                }
            }
            &.helper {
                padding-right: 0;
            }
        }
    }
}
