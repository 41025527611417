p.avatar-bg.a {
    background-color: #ad96e5;
}

p.avatar-bg.b {
    background-color: #ffc93c;
}

p.avatar-bg.c {
    background-color: #ad96e5;
}

p.avatar-bg.d {
    background-color: #38b3c2;
}

p.avatar-bg.e {
    background-color: #38b3c2;
}

p.avatar-bg.f {
    background-color: #ad96e5;
}

p.avatar-bg.g {
    background-color: #333333;
}

p.avatar-bg.h {
    background-color: #31326f;
}

p.avatar-bg.i {
    background-color: #ad96e5;
}

p.avatar-bg.j {
    background-color: #ffc93c;
}

p.avatar-bg.k {
    background-color: #333333;
}

p.avatar-bg.l {
    background-color: #333333;
}

p.avatar-bg.m {
    background-color: #ffc93c;
}

p.avatar-bg.n {
    background-color: #ffc93c;
}

p.avatar-bg.o {
    background-color: #31326f;
}

p.avatar-bg.p {
    background-color: #31326f;
}

p.avatar-bg.q {
    background-color: #ad96e5;
}

p.avatar-bg.r {
    background-color: #31326f;
}

p.avatar-bg.s {
    background-color: #333333;
}

p.avatar-bg.t {
    background-color: #ffc93c;
}

p.avatar-bg.u {
    background-color: #31326f;
}

p.avatar-bg.v {
    background-color: #333333;
}

p.avatar-bg.w {
    background-color: #38b3c2;
}

p.avatar-bg.x {
    background-color: #31326f;
}

p.avatar-bg.y {
    background-color: #31326f;
}

p.avatar-bg.z {
    background-color: #ad96e5;
}

p.avatar-bg.aa {
    background-color: #ffc93c;
}

p.avatar-bg.ab {
    background-color: #ad96e5;
}

p.avatar-bg.ac {
    background-color: #38b3c2;
}

p.avatar-bg.ad {
    background-color: #ffc93c;
}

p.avatar-bg.ae {
    background-color: #38b3c2;
}

p.avatar-bg.af {
    background-color: #38b3c2;
}

p.avatar-bg.ag {
    background-color: #38b3c2;
}

p.avatar-bg.ah {
    background-color: #38b3c2;
}

p.avatar-bg.ai {
    background-color: #31326f;
}

p.avatar-bg.aj {
    background-color: #31326f;
}

p.avatar-bg.ak {
    background-color: #ad96e5;
}

p.avatar-bg.al {
    background-color: #ffc93c;
}

p.avatar-bg.am {
    background-color: #31326f;
}

p.avatar-bg.an {
    background-color: #38b3c2;
}

p.avatar-bg.ao {
    background-color: #31326f;
}

p.avatar-bg.ap {
    background-color: #ad96e5;
}

p.avatar-bg.aq {
    background-color: #ad96e5;
}

p.avatar-bg.ar {
    background-color: #38b3c2;
}

p.avatar-bg.as {
    background-color: #333333;
}

p.avatar-bg.at {
    background-color: #333333;
}

p.avatar-bg.au {
    background-color: #31326f;
}

p.avatar-bg.av {
    background-color: #ffc93c;
}

p.avatar-bg.aw {
    background-color: #31326f;
}

p.avatar-bg.ax {
    background-color: #333333;
}

p.avatar-bg.ay {
    background-color: #31326f;
}

p.avatar-bg.az {
    background-color: #333333;
}

p.avatar-bg.ba {
    background-color: #ffc93c;
}

p.avatar-bg.bb {
    background-color: #31326f;
}

p.avatar-bg.bc {
    background-color: #ad96e5;
}

p.avatar-bg.bd {
    background-color: #333333;
}

p.avatar-bg.be {
    background-color: #ffc93c;
}

p.avatar-bg.bf {
    background-color: #31326f;
}

p.avatar-bg.bg {
    background-color: #333333;
}

p.avatar-bg.bh {
    background-color: #ffc93c;
}

p.avatar-bg.bi {
    background-color: #ffc93c;
}

p.avatar-bg.bj {
    background-color: #31326f;
}

p.avatar-bg.bk {
    background-color: #ffc93c;
}

p.avatar-bg.bl {
    background-color: #ffc93c;
}

p.avatar-bg.bm {
    background-color: #333333;
}

p.avatar-bg.bn {
    background-color: #ad96e5;
}

p.avatar-bg.bo {
    background-color: #333333;
}

p.avatar-bg.bp {
    background-color: #333333;
}

p.avatar-bg.bq {
    background-color: #38b3c2;
}

p.avatar-bg.br {
    background-color: #ffc93c;
}

p.avatar-bg.bs {
    background-color: #333333;
}

p.avatar-bg.bt {
    background-color: #333333;
}

p.avatar-bg.bu {
    background-color: #ffc93c;
}

p.avatar-bg.bv {
    background-color: #38b3c2;
}

p.avatar-bg.bw {
    background-color: #38b3c2;
}

p.avatar-bg.bx {
    background-color: #31326f;
}

p.avatar-bg.by {
    background-color: #31326f;
}

p.avatar-bg.bz {
    background-color: #38b3c2;
}

p.avatar-bg.ca {
    background-color: #ffc93c;
}

p.avatar-bg.cb {
    background-color: #38b3c2;
}

p.avatar-bg.cc {
    background-color: #ffc93c;
}

p.avatar-bg.cd {
    background-color: #ad96e5;
}

p.avatar-bg.ce {
    background-color: #38b3c2;
}

p.avatar-bg.cf {
    background-color: #38b3c2;
}

p.avatar-bg.cg {
    background-color: #31326f;
}

p.avatar-bg.ch {
    background-color: #38b3c2;
}

p.avatar-bg.ci {
    background-color: #ffc93c;
}

p.avatar-bg.cj {
    background-color: #38b3c2;
}

p.avatar-bg.ck {
    background-color: #ffc93c;
}

p.avatar-bg.cl {
    background-color: #38b3c2;
}

p.avatar-bg.cm {
    background-color: #ad96e5;
}

p.avatar-bg.cn {
    background-color: #333333;
}

p.avatar-bg.co {
    background-color: #38b3c2;
}

p.avatar-bg.cp {
    background-color: #ad96e5;
}

p.avatar-bg.cq {
    background-color: #ffc93c;
}

p.avatar-bg.cr {
    background-color: #ad96e5;
}

p.avatar-bg.cs {
    background-color: #333333;
}

p.avatar-bg.ct {
    background-color: #ffc93c;
}

p.avatar-bg.cu {
    background-color: #ad96e5;
}

p.avatar-bg.cv {
    background-color: #38b3c2;
}

p.avatar-bg.cw {
    background-color: #333333;
}

p.avatar-bg.cx {
    background-color: #ad96e5;
}

p.avatar-bg.cy {
    background-color: #ad96e5;
}

p.avatar-bg.cz {
    background-color: #333333;
}

p.avatar-bg.da {
    background-color: #38b3c2;
}

p.avatar-bg.db {
    background-color: #ad96e5;
}

p.avatar-bg.dc {
    background-color: #ffc93c;
}

p.avatar-bg.dd {
    background-color: #31326f;
}

p.avatar-bg.de {
    background-color: #31326f;
}

p.avatar-bg.df {
    background-color: #31326f;
}

p.avatar-bg.dg {
    background-color: #31326f;
}

p.avatar-bg.dh {
    background-color: #38b3c2;
}

p.avatar-bg.di {
    background-color: #333333;
}

p.avatar-bg.dj {
    background-color: #38b3c2;
}

p.avatar-bg.dk {
    background-color: #ad96e5;
}

p.avatar-bg.dl {
    background-color: #31326f;
}

p.avatar-bg.dm {
    background-color: #31326f;
}

p.avatar-bg.dn {
    background-color: #38b3c2;
}

p.avatar-bg.do {
    background-color: #38b3c2;
}

p.avatar-bg.dp {
    background-color: #38b3c2;
}

p.avatar-bg.dq {
    background-color: #ffc93c;
}

p.avatar-bg.dr {
    background-color: #ffc93c;
}

p.avatar-bg.ds {
    background-color: #333333;
}

p.avatar-bg.dt {
    background-color: #333333;
}

p.avatar-bg.du {
    background-color: #31326f;
}

p.avatar-bg.dv {
    background-color: #333333;
}

p.avatar-bg.dw {
    background-color: #ad96e5;
}

p.avatar-bg.dx {
    background-color: #333333;
}

p.avatar-bg.dy {
    background-color: #38b3c2;
}

p.avatar-bg.dz {
    background-color: #333333;
}

p.avatar-bg.ea {
    background-color: #38b3c2;
}

p.avatar-bg.eb {
    background-color: #38b3c2;
}

p.avatar-bg.ec {
    background-color: #333333;
}

p.avatar-bg.ed {
    background-color: #ad96e5;
}

p.avatar-bg.ee {
    background-color: #ad96e5;
}

p.avatar-bg.ef {
    background-color: #ad96e5;
}

p.avatar-bg.eg {
    background-color: #38b3c2;
}

p.avatar-bg.eh {
    background-color: #ffc93c;
}

p.avatar-bg.ei {
    background-color: #ffc93c;
}

p.avatar-bg.ej {
    background-color: #31326f;
}

p.avatar-bg.ek {
    background-color: #ffc93c;
}

p.avatar-bg.el {
    background-color: #333333;
}

p.avatar-bg.em {
    background-color: #ad96e5;
}

p.avatar-bg.en {
    background-color: #ffc93c;
}

p.avatar-bg.eo {
    background-color: #31326f;
}

p.avatar-bg.ep {
    background-color: #38b3c2;
}

p.avatar-bg.eq {
    background-color: #31326f;
}

p.avatar-bg.er {
    background-color: #ffc93c;
}

p.avatar-bg.es {
    background-color: #333333;
}

p.avatar-bg.et {
    background-color: #38b3c2;
}

p.avatar-bg.eu {
    background-color: #333333;
}

p.avatar-bg.ev {
    background-color: #38b3c2;
}

p.avatar-bg.ew {
    background-color: #333333;
}

p.avatar-bg.ex {
    background-color: #ffc93c;
}

p.avatar-bg.ey {
    background-color: #ad96e5;
}

p.avatar-bg.ez {
    background-color: #333333;
}

p.avatar-bg.fa {
    background-color: #31326f;
}

p.avatar-bg.fb {
    background-color: #31326f;
}

p.avatar-bg.fc {
    background-color: #38b3c2;
}

p.avatar-bg.fd {
    background-color: #ad96e5;
}

p.avatar-bg.fe {
    background-color: #ad96e5;
}

p.avatar-bg.ff {
    background-color: #38b3c2;
}

p.avatar-bg.fg {
    background-color: #ad96e5;
}

p.avatar-bg.fh {
    background-color: #ad96e5;
}

p.avatar-bg.fi {
    background-color: #31326f;
}

p.avatar-bg.fj {
    background-color: #ffc93c;
}

p.avatar-bg.fk {
    background-color: #38b3c2;
}

p.avatar-bg.fl {
    background-color: #ffc93c;
}

p.avatar-bg.fm {
    background-color: #333333;
}

p.avatar-bg.fn {
    background-color: #38b3c2;
}

p.avatar-bg.fo {
    background-color: #333333;
}

p.avatar-bg.fp {
    background-color: #31326f;
}

p.avatar-bg.fq {
    background-color: #333333;
}

p.avatar-bg.fr {
    background-color: #31326f;
}

p.avatar-bg.fs {
    background-color: #38b3c2;
}

p.avatar-bg.ft {
    background-color: #333333;
}

p.avatar-bg.fu {
    background-color: #333333;
}

p.avatar-bg.fv {
    background-color: #ffc93c;
}

p.avatar-bg.fw {
    background-color: #ad96e5;
}

p.avatar-bg.fx {
    background-color: #ad96e5;
}

p.avatar-bg.fy {
    background-color: #38b3c2;
}

p.avatar-bg.fz {
    background-color: #31326f;
}

p.avatar-bg.ga {
    background-color: #38b3c2;
}

p.avatar-bg.gb {
    background-color: #ad96e5;
}

p.avatar-bg.gc {
    background-color: #38b3c2;
}

p.avatar-bg.gd {
    background-color: #38b3c2;
}

p.avatar-bg.ge {
    background-color: #38b3c2;
}

p.avatar-bg.gf {
    background-color: #ad96e5;
}

p.avatar-bg.gg {
    background-color: #31326f;
}

p.avatar-bg.gh {
    background-color: #333333;
}

p.avatar-bg.gi {
    background-color: #31326f;
}

p.avatar-bg.gj {
    background-color: #333333;
}

p.avatar-bg.gk {
    background-color: #ffc93c;
}

p.avatar-bg.gl {
    background-color: #ffc93c;
}

p.avatar-bg.gm {
    background-color: #333333;
}

p.avatar-bg.gn {
    background-color: #38b3c2;
}

p.avatar-bg.go {
    background-color: #ffc93c;
}

p.avatar-bg.gp {
    background-color: #38b3c2;
}

p.avatar-bg.gq {
    background-color: #ad96e5;
}

p.avatar-bg.gr {
    background-color: #ad96e5;
}

p.avatar-bg.gs {
    background-color: #333333;
}

p.avatar-bg.gt {
    background-color: #31326f;
}

p.avatar-bg.gu {
    background-color: #38b3c2;
}

p.avatar-bg.gv {
    background-color: #31326f;
}

p.avatar-bg.gw {
    background-color: #38b3c2;
}

p.avatar-bg.gx {
    background-color: #ad96e5;
}

p.avatar-bg.gy {
    background-color: #333333;
}

p.avatar-bg.gz {
    background-color: #ffc93c;
}

p.avatar-bg.ha {
    background-color: #333333;
}

p.avatar-bg.hb {
    background-color: #333333;
}

p.avatar-bg.hc {
    background-color: #ad96e5;
}

p.avatar-bg.hd {
    background-color: #31326f;
}

p.avatar-bg.he {
    background-color: #333333;
}

p.avatar-bg.hf {
    background-color: #ffc93c;
}

p.avatar-bg.hg {
    background-color: #ad96e5;
}

p.avatar-bg.hh {
    background-color: #333333;
}

p.avatar-bg.hi {
    background-color: #333333;
}

p.avatar-bg.hj {
    background-color: #31326f;
}

p.avatar-bg.hk {
    background-color: #31326f;
}

p.avatar-bg.hl {
    background-color: #31326f;
}

p.avatar-bg.hm {
    background-color: #ffc93c;
}

p.avatar-bg.hn {
    background-color: #333333;
}

p.avatar-bg.ho {
    background-color: #38b3c2;
}

p.avatar-bg.hp {
    background-color: #333333;
}

p.avatar-bg.hq {
    background-color: #333333;
}

p.avatar-bg.hr {
    background-color: #333333;
}

p.avatar-bg.hs {
    background-color: #333333;
}

p.avatar-bg.ht {
    background-color: #333333;
}

p.avatar-bg.hu {
    background-color: #ffc93c;
}

p.avatar-bg.hv {
    background-color: #31326f;
}

p.avatar-bg.hw {
    background-color: #31326f;
}

p.avatar-bg.hx {
    background-color: #ffc93c;
}

p.avatar-bg.hy {
    background-color: #38b3c2;
}

p.avatar-bg.hz {
    background-color: #ad96e5;
}

p.avatar-bg.ia {
    background-color: #ad96e5;
}

p.avatar-bg.ib {
    background-color: #333333;
}

p.avatar-bg.ic {
    background-color: #ad96e5;
}

p.avatar-bg.id {
    background-color: #31326f;
}

p.avatar-bg.ie {
    background-color: #ffc93c;
}

p.avatar-bg.if {
    background-color: #31326f;
}

p.avatar-bg.ig {
    background-color: #31326f;
}

p.avatar-bg.ih {
    background-color: #31326f;
}

p.avatar-bg.ii {
    background-color: #31326f;
}

p.avatar-bg.ij {
    background-color: #ad96e5;
}

p.avatar-bg.ik {
    background-color: #333333;
}

p.avatar-bg.il {
    background-color: #38b3c2;
}

p.avatar-bg.im {
    background-color: #333333;
}

p.avatar-bg.in {
    background-color: #ffc93c;
}

p.avatar-bg.io {
    background-color: #333333;
}

p.avatar-bg.ip {
    background-color: #ad96e5;
}

p.avatar-bg.iq {
    background-color: #ad96e5;
}

p.avatar-bg.ir {
    background-color: #ffc93c;
}

p.avatar-bg.is {
    background-color: #ad96e5;
}

p.avatar-bg.it {
    background-color: #38b3c2;
}

p.avatar-bg.iu {
    background-color: #ffc93c;
}

p.avatar-bg.iv {
    background-color: #333333;
}

p.avatar-bg.iw {
    background-color: #38b3c2;
}

p.avatar-bg.ix {
    background-color: #ad96e5;
}

p.avatar-bg.iy {
    background-color: #38b3c2;
}

p.avatar-bg.iz {
    background-color: #333333;
}

p.avatar-bg.ja {
    background-color: #31326f;
}

p.avatar-bg.jb {
    background-color: #ad96e5;
}

p.avatar-bg.jc {
    background-color: #ad96e5;
}

p.avatar-bg.jd {
    background-color: #333333;
}

p.avatar-bg.je {
    background-color: #ffc93c;
}

p.avatar-bg.jf {
    background-color: #333333;
}

p.avatar-bg.jg {
    background-color: #ad96e5;
}

p.avatar-bg.jh {
    background-color: #ffc93c;
}

p.avatar-bg.ji {
    background-color: #31326f;
}

p.avatar-bg.jj {
    background-color: #333333;
}

p.avatar-bg.jk {
    background-color: #38b3c2;
}

p.avatar-bg.jl {
    background-color: #38b3c2;
}

p.avatar-bg.jm {
    background-color: #ffc93c;
}

p.avatar-bg.jn {
    background-color: #333333;
}

p.avatar-bg.jo {
    background-color: #ad96e5;
}

p.avatar-bg.jp {
    background-color: #38b3c2;
}

p.avatar-bg.jq {
    background-color: #ffc93c;
}

p.avatar-bg.jr {
    background-color: #38b3c2;
}

p.avatar-bg.js {
    background-color: #333333;
}

p.avatar-bg.jt {
    background-color: #38b3c2;
}

p.avatar-bg.ju {
    background-color: #333333;
}

p.avatar-bg.jv {
    background-color: #31326f;
}

p.avatar-bg.jw {
    background-color: #ffc93c;
}

p.avatar-bg.jx {
    background-color: #ffc93c;
}

p.avatar-bg.jy {
    background-color: #31326f;
}

p.avatar-bg.jz {
    background-color: #38b3c2;
}

p.avatar-bg.ka {
    background-color: #ad96e5;
}

p.avatar-bg.kb {
    background-color: #31326f;
}

p.avatar-bg.kc {
    background-color: #31326f;
}

p.avatar-bg.kd {
    background-color: #38b3c2;
}

p.avatar-bg.ke {
    background-color: #38b3c2;
}

p.avatar-bg.kf {
    background-color: #38b3c2;
}

p.avatar-bg.kg {
    background-color: #333333;
}

p.avatar-bg.kh {
    background-color: #ad96e5;
}

p.avatar-bg.ki {
    background-color: #ffc93c;
}

p.avatar-bg.kj {
    background-color: #31326f;
}

p.avatar-bg.kk {
    background-color: #31326f;
}

p.avatar-bg.kl {
    background-color: #ad96e5;
}

p.avatar-bg.km {
    background-color: #ad96e5;
}

p.avatar-bg.kn {
    background-color: #ad96e5;
}

p.avatar-bg.ko {
    background-color: #ffc93c;
}

p.avatar-bg.kp {
    background-color: #31326f;
}

p.avatar-bg.kq {
    background-color: #31326f;
}

p.avatar-bg.kr {
    background-color: #333333;
}

p.avatar-bg.ks {
    background-color: #333333;
}

p.avatar-bg.kt {
    background-color: #38b3c2;
}

p.avatar-bg.ku {
    background-color: #31326f;
}

p.avatar-bg.kv {
    background-color: #31326f;
}

p.avatar-bg.kw {
    background-color: #ffc93c;
}

p.avatar-bg.kx {
    background-color: #38b3c2;
}

p.avatar-bg.ky {
    background-color: #31326f;
}

p.avatar-bg.kz {
    background-color: #31326f;
}

p.avatar-bg.la {
    background-color: #38b3c2;
}

p.avatar-bg.lb {
    background-color: #ad96e5;
}

p.avatar-bg.lc {
    background-color: #31326f;
}

p.avatar-bg.ld {
    background-color: #ffc93c;
}

p.avatar-bg.le {
    background-color: #ffc93c;
}

p.avatar-bg.lf {
    background-color: #31326f;
}

p.avatar-bg.lg {
    background-color: #333333;
}

p.avatar-bg.lh {
    background-color: #38b3c2;
}

p.avatar-bg.li {
    background-color: #ad96e5;
}

p.avatar-bg.lj {
    background-color: #ad96e5;
}

p.avatar-bg.lk {
    background-color: #333333;
}

p.avatar-bg.ll {
    background-color: #ad96e5;
}

p.avatar-bg.lm {
    background-color: #31326f;
}

p.avatar-bg.ln {
    background-color: #333333;
}

p.avatar-bg.lo {
    background-color: #31326f;
}

p.avatar-bg.lp {
    background-color: #38b3c2;
}

p.avatar-bg.lq {
    background-color: #333333;
}

p.avatar-bg.lr {
    background-color: #31326f;
}

p.avatar-bg.ls {
    background-color: #ad96e5;
}

p.avatar-bg.lt {
    background-color: #ffc93c;
}

p.avatar-bg.lu {
    background-color: #333333;
}

p.avatar-bg.lv {
    background-color: #38b3c2;
}

p.avatar-bg.lw {
    background-color: #38b3c2;
}

p.avatar-bg.lx {
    background-color: #ffc93c;
}

p.avatar-bg.ly {
    background-color: #ad96e5;
}

p.avatar-bg.lz {
    background-color: #333333;
}

p.avatar-bg.ma {
    background-color: #31326f;
}

p.avatar-bg.mb {
    background-color: #38b3c2;
}

p.avatar-bg.mc {
    background-color: #31326f;
}

p.avatar-bg.md {
    background-color: #333333;
}

p.avatar-bg.me {
    background-color: #333333;
}

p.avatar-bg.mf {
    background-color: #ad96e5;
}

p.avatar-bg.mg {
    background-color: #31326f;
}

p.avatar-bg.mh {
    background-color: #ffc93c;
}

p.avatar-bg.mi {
    background-color: #ffc93c;
}

p.avatar-bg.mj {
    background-color: #ad96e5;
}

p.avatar-bg.mk {
    background-color: #ad96e5;
}

p.avatar-bg.ml {
    background-color: #333333;
}

p.avatar-bg.mm {
    background-color: #ffc93c;
}

p.avatar-bg.mn {
    background-color: #31326f;
}

p.avatar-bg.mo {
    background-color: #333333;
}

p.avatar-bg.mp {
    background-color: #ffc93c;
}

p.avatar-bg.mq {
    background-color: #31326f;
}

p.avatar-bg.mr {
    background-color: #38b3c2;
}

p.avatar-bg.ms {
    background-color: #333333;
}

p.avatar-bg.mt {
    background-color: #38b3c2;
}

p.avatar-bg.mu {
    background-color: #ad96e5;
}

p.avatar-bg.mv {
    background-color: #31326f;
}

p.avatar-bg.mw {
    background-color: #ad96e5;
}

p.avatar-bg.mx {
    background-color: #38b3c2;
}

p.avatar-bg.my {
    background-color: #ffc93c;
}

p.avatar-bg.mz {
    background-color: #333333;
}

p.avatar-bg.na {
    background-color: #333333;
}

p.avatar-bg.nb {
    background-color: #333333;
}

p.avatar-bg.nc {
    background-color: #333333;
}

p.avatar-bg.nd {
    background-color: #333333;
}

p.avatar-bg.ne {
    background-color: #31326f;
}

p.avatar-bg.nf {
    background-color: #38b3c2;
}

p.avatar-bg.ng {
    background-color: #333333;
}

p.avatar-bg.nh {
    background-color: #31326f;
}

p.avatar-bg.ni {
    background-color: #ad96e5;
}

p.avatar-bg.nj {
    background-color: #ad96e5;
}

p.avatar-bg.nk {
    background-color: #38b3c2;
}

p.avatar-bg.nl {
    background-color: #ad96e5;
}

p.avatar-bg.nm {
    background-color: #ad96e5;
}

p.avatar-bg.nn {
    background-color: #333333;
}

p.avatar-bg.no {
    background-color: #ffc93c;
}

p.avatar-bg.np {
    background-color: #333333;
}

p.avatar-bg.nq {
    background-color: #31326f;
}

p.avatar-bg.nr {
    background-color: #31326f;
}

p.avatar-bg.ns {
    background-color: #38b3c2;
}

p.avatar-bg.nt {
    background-color: #ffc93c;
}

p.avatar-bg.nu {
    background-color: #38b3c2;
}

p.avatar-bg.nv {
    background-color: #333333;
}

p.avatar-bg.nw {
    background-color: #31326f;
}

p.avatar-bg.nx {
    background-color: #31326f;
}

p.avatar-bg.ny {
    background-color: #38b3c2;
}

p.avatar-bg.nz {
    background-color: #38b3c2;
}

p.avatar-bg.oa {
    background-color: #ffc93c;
}

p.avatar-bg.ob {
    background-color: #333333;
}

p.avatar-bg.oc {
    background-color: #ffc93c;
}

p.avatar-bg.od {
    background-color: #333333;
}

p.avatar-bg.oe {
    background-color: #ffc93c;
}

p.avatar-bg.of {
    background-color: #333333;
}

p.avatar-bg.og {
    background-color: #333333;
}

p.avatar-bg.oh {
    background-color: #333333;
}

p.avatar-bg.oi {
    background-color: #38b3c2;
}

p.avatar-bg.oj {
    background-color: #38b3c2;
}

p.avatar-bg.ok {
    background-color: #ad96e5;
}

p.avatar-bg.ol {
    background-color: #ad96e5;
}

p.avatar-bg.om {
    background-color: #31326f;
}

p.avatar-bg.on {
    background-color: #ffc93c;
}

p.avatar-bg.oo {
    background-color: #333333;
}

p.avatar-bg.op {
    background-color: #ad96e5;
}

p.avatar-bg.oq {
    background-color: #38b3c2;
}

p.avatar-bg.or {
    background-color: #ffc93c;
}

p.avatar-bg.os {
    background-color: #ffc93c;
}

p.avatar-bg.ot {
    background-color: #333333;
}

p.avatar-bg.ou {
    background-color: #38b3c2;
}

p.avatar-bg.ov {
    background-color: #333333;
}

p.avatar-bg.ow {
    background-color: #333333;
}

p.avatar-bg.ox {
    background-color: #31326f;
}

p.avatar-bg.oy {
    background-color: #31326f;
}

p.avatar-bg.oz {
    background-color: #ffc93c;
}

p.avatar-bg.pa {
    background-color: #ad96e5;
}

p.avatar-bg.pb {
    background-color: #ad96e5;
}

p.avatar-bg.pc {
    background-color: #ad96e5;
}

p.avatar-bg.pd {
    background-color: #31326f;
}

p.avatar-bg.pe {
    background-color: #333333;
}

p.avatar-bg.pf {
    background-color: #ffc93c;
}

p.avatar-bg.pg {
    background-color: #ad96e5;
}

p.avatar-bg.ph {
    background-color: #ad96e5;
}

p.avatar-bg.pi {
    background-color: #333333;
}

p.avatar-bg.pj {
    background-color: #ffc93c;
}

p.avatar-bg.pk {
    background-color: #31326f;
}

p.avatar-bg.pl {
    background-color: #ffc93c;
}

p.avatar-bg.pm {
    background-color: #31326f;
}

p.avatar-bg.pn {
    background-color: #38b3c2;
}

p.avatar-bg.po {
    background-color: #38b3c2;
}

p.avatar-bg.pp {
    background-color: #31326f;
}

p.avatar-bg.pq {
    background-color: #ffc93c;
}

p.avatar-bg.pr {
    background-color: #ad96e5;
}

p.avatar-bg.ps {
    background-color: #ffc93c;
}

p.avatar-bg.pt {
    background-color: #333333;
}

p.avatar-bg.pu {
    background-color: #333333;
}

p.avatar-bg.pv {
    background-color: #333333;
}

p.avatar-bg.pw {
    background-color: #38b3c2;
}

p.avatar-bg.px {
    background-color: #333333;
}

p.avatar-bg.py {
    background-color: #ffc93c;
}

p.avatar-bg.pz {
    background-color: #ffc93c;
}

p.avatar-bg.qa {
    background-color: #ad96e5;
}

p.avatar-bg.qb {
    background-color: #333333;
}

p.avatar-bg.qc {
    background-color: #ffc93c;
}

p.avatar-bg.qd {
    background-color: #31326f;
}

p.avatar-bg.qe {
    background-color: #333333;
}

p.avatar-bg.qf {
    background-color: #ffc93c;
}

p.avatar-bg.qg {
    background-color: #38b3c2;
}

p.avatar-bg.qh {
    background-color: #38b3c2;
}

p.avatar-bg.qi {
    background-color: #31326f;
}

p.avatar-bg.qj {
    background-color: #333333;
}

p.avatar-bg.qk {
    background-color: #333333;
}

p.avatar-bg.ql {
    background-color: #ffc93c;
}

p.avatar-bg.qm {
    background-color: #333333;
}

p.avatar-bg.qn {
    background-color: #333333;
}

p.avatar-bg.qo {
    background-color: #38b3c2;
}

p.avatar-bg.qp {
    background-color: #333333;
}

p.avatar-bg.qq {
    background-color: #333333;
}

p.avatar-bg.qr {
    background-color: #ad96e5;
}

p.avatar-bg.qs {
    background-color: #ad96e5;
}

p.avatar-bg.qt {
    background-color: #31326f;
}

p.avatar-bg.qu {
    background-color: #38b3c2;
}

p.avatar-bg.qv {
    background-color: #31326f;
}

p.avatar-bg.qw {
    background-color: #ffc93c;
}

p.avatar-bg.qx {
    background-color: #31326f;
}

p.avatar-bg.qy {
    background-color: #ffc93c;
}

p.avatar-bg.qz {
    background-color: #333333;
}

p.avatar-bg.ra {
    background-color: #ffc93c;
}

p.avatar-bg.rb {
    background-color: #ad96e5;
}

p.avatar-bg.rc {
    background-color: #333333;
}

p.avatar-bg.rd {
    background-color: #ffc93c;
}

p.avatar-bg.re {
    background-color: #333333;
}

p.avatar-bg.rf {
    background-color: #31326f;
}

p.avatar-bg.rg {
    background-color: #31326f;
}

p.avatar-bg.rh {
    background-color: #ad96e5;
}

p.avatar-bg.ri {
    background-color: #38b3c2;
}

p.avatar-bg.rj {
    background-color: #ffc93c;
}

p.avatar-bg.rk {
    background-color: #333333;
}

p.avatar-bg.rl {
    background-color: #38b3c2;
}

p.avatar-bg.rm {
    background-color: #333333;
}

p.avatar-bg.rn {
    background-color: #333333;
}

p.avatar-bg.ro {
    background-color: #ffc93c;
}

p.avatar-bg.rp {
    background-color: #38b3c2;
}

p.avatar-bg.rq {
    background-color: #ffc93c;
}

p.avatar-bg.rr {
    background-color: #333333;
}

p.avatar-bg.rs {
    background-color: #333333;
}

p.avatar-bg.rt {
    background-color: #31326f;
}

p.avatar-bg.ru {
    background-color: #ad96e5;
}

p.avatar-bg.rv {
    background-color: #333333;
}

p.avatar-bg.rw {
    background-color: #333333;
}

p.avatar-bg.rx {
    background-color: #31326f;
}

p.avatar-bg.ry {
    background-color: #38b3c2;
}

p.avatar-bg.rz {
    background-color: #ad96e5;
}

p.avatar-bg.sa {
    background-color: #ffc93c;
}

p.avatar-bg.sb {
    background-color: #ffc93c;
}

p.avatar-bg.sc {
    background-color: #38b3c2;
}

p.avatar-bg.sd {
    background-color: #38b3c2;
}

p.avatar-bg.se {
    background-color: #ad96e5;
}

p.avatar-bg.sf {
    background-color: #333333;
}

p.avatar-bg.sg {
    background-color: #38b3c2;
}

p.avatar-bg.sh {
    background-color: #38b3c2;
}

p.avatar-bg.si {
    background-color: #ad96e5;
}

p.avatar-bg.sj {
    background-color: #31326f;
}

p.avatar-bg.sk {
    background-color: #ffc93c;
}

p.avatar-bg.sl {
    background-color: #31326f;
}

p.avatar-bg.sm {
    background-color: #333333;
}

p.avatar-bg.sn {
    background-color: #333333;
}

p.avatar-bg.so {
    background-color: #ffc93c;
}

p.avatar-bg.sp {
    background-color: #ffc93c;
}

p.avatar-bg.sq {
    background-color: #ffc93c;
}

p.avatar-bg.sr {
    background-color: #333333;
}

p.avatar-bg.ss {
    background-color: #38b3c2;
}

p.avatar-bg.st {
    background-color: #38b3c2;
}

p.avatar-bg.su {
    background-color: #333333;
}

p.avatar-bg.sv {
    background-color: #333333;
}

p.avatar-bg.sw {
    background-color: #ffc93c;
}

p.avatar-bg.sx {
    background-color: #ad96e5;
}

p.avatar-bg.sy {
    background-color: #38b3c2;
}

p.avatar-bg.sz {
    background-color: #38b3c2;
}

p.avatar-bg.ta {
    background-color: #38b3c2;
}

p.avatar-bg.tb {
    background-color: #31326f;
}

p.avatar-bg.tc {
    background-color: #38b3c2;
}

p.avatar-bg.td {
    background-color: #333333;
}

p.avatar-bg.te {
    background-color: #38b3c2;
}

p.avatar-bg.tf {
    background-color: #31326f;
}

p.avatar-bg.tg {
    background-color: #31326f;
}

p.avatar-bg.th {
    background-color: #ffc93c;
}

p.avatar-bg.ti {
    background-color: #31326f;
}

p.avatar-bg.tj {
    background-color: #31326f;
}

p.avatar-bg.tk {
    background-color: #ad96e5;
}

p.avatar-bg.tl {
    background-color: #ffc93c;
}

p.avatar-bg.tm {
    background-color: #ad96e5;
}

p.avatar-bg.tn {
    background-color: #333333;
}

p.avatar-bg.to {
    background-color: #333333;
}

p.avatar-bg.tp {
    background-color: #38b3c2;
}

p.avatar-bg.tq {
    background-color: #38b3c2;
}

p.avatar-bg.tr {
    background-color: #333333;
}

p.avatar-bg.ts {
    background-color: #333333;
}

p.avatar-bg.tt {
    background-color: #31326f;
}

p.avatar-bg.tu {
    background-color: #ffc93c;
}

p.avatar-bg.tv {
    background-color: #38b3c2;
}

p.avatar-bg.tw {
    background-color: #ad96e5;
}

p.avatar-bg.tx {
    background-color: #ffc93c;
}

p.avatar-bg.ty {
    background-color: #ad96e5;
}

p.avatar-bg.tz {
    background-color: #333333;
}

p.avatar-bg.ua {
    background-color: #38b3c2;
}

p.avatar-bg.ub {
    background-color: #38b3c2;
}

p.avatar-bg.uc {
    background-color: #38b3c2;
}

p.avatar-bg.ud {
    background-color: #ad96e5;
}

p.avatar-bg.ue {
    background-color: #38b3c2;
}

p.avatar-bg.uf {
    background-color: #38b3c2;
}

p.avatar-bg.ug {
    background-color: #38b3c2;
}

p.avatar-bg.uh {
    background-color: #333333;
}

p.avatar-bg.ui {
    background-color: #31326f;
}

p.avatar-bg.uj {
    background-color: #333333;
}

p.avatar-bg.uk {
    background-color: #333333;
}

p.avatar-bg.ul {
    background-color: #38b3c2;
}

p.avatar-bg.um {
    background-color: #ad96e5;
}

p.avatar-bg.un {
    background-color: #38b3c2;
}

p.avatar-bg.uo {
    background-color: #ad96e5;
}

p.avatar-bg.up {
    background-color: #333333;
}

p.avatar-bg.uq {
    background-color: #38b3c2;
}

p.avatar-bg.ur {
    background-color: #333333;
}

p.avatar-bg.us {
    background-color: #38b3c2;
}

p.avatar-bg.ut {
    background-color: #ffc93c;
}

p.avatar-bg.uu {
    background-color: #333333;
}

p.avatar-bg.uv {
    background-color: #38b3c2;
}

p.avatar-bg.uw {
    background-color: #ffc93c;
}

p.avatar-bg.ux {
    background-color: #333333;
}

p.avatar-bg.uy {
    background-color: #ad96e5;
}

p.avatar-bg.uz {
    background-color: #38b3c2;
}

p.avatar-bg.va {
    background-color: #ffc93c;
}

p.avatar-bg.vb {
    background-color: #ad96e5;
}

p.avatar-bg.vc {
    background-color: #ad96e5;
}

p.avatar-bg.vd {
    background-color: #ad96e5;
}

p.avatar-bg.ve {
    background-color: #333333;
}

p.avatar-bg.vf {
    background-color: #ad96e5;
}

p.avatar-bg.vg {
    background-color: #38b3c2;
}

p.avatar-bg.vh {
    background-color: #38b3c2;
}

p.avatar-bg.vi {
    background-color: #333333;
}

p.avatar-bg.vj {
    background-color: #ad96e5;
}

p.avatar-bg.vk {
    background-color: #ffc93c;
}

p.avatar-bg.vl {
    background-color: #333333;
}

p.avatar-bg.vm {
    background-color: #38b3c2;
}

p.avatar-bg.vn {
    background-color: #38b3c2;
}

p.avatar-bg.vo {
    background-color: #ad96e5;
}

p.avatar-bg.vp {
    background-color: #ffc93c;
}

p.avatar-bg.vq {
    background-color: #31326f;
}

p.avatar-bg.vr {
    background-color: #ffc93c;
}

p.avatar-bg.vs {
    background-color: #333333;
}

p.avatar-bg.vt {
    background-color: #38b3c2;
}

p.avatar-bg.vu {
    background-color: #38b3c2;
}

p.avatar-bg.vv {
    background-color: #ffc93c;
}

p.avatar-bg.vw {
    background-color: #31326f;
}

p.avatar-bg.vx {
    background-color: #ad96e5;
}

p.avatar-bg.vy {
    background-color: #31326f;
}

p.avatar-bg.vz {
    background-color: #38b3c2;
}

p.avatar-bg.wa {
    background-color: #38b3c2;
}

p.avatar-bg.wb {
    background-color: #31326f;
}

p.avatar-bg.wc {
    background-color: #38b3c2;
}

p.avatar-bg.wd {
    background-color: #31326f;
}

p.avatar-bg.we {
    background-color: #333333;
}

p.avatar-bg.wf {
    background-color: #38b3c2;
}

p.avatar-bg.wg {
    background-color: #38b3c2;
}

p.avatar-bg.wh {
    background-color: #ad96e5;
}

p.avatar-bg.wi {
    background-color: #ffc93c;
}

p.avatar-bg.wj {
    background-color: #ffc93c;
}

p.avatar-bg.wk {
    background-color: #38b3c2;
}

p.avatar-bg.wl {
    background-color: #ffc93c;
}

p.avatar-bg.wm {
    background-color: #ffc93c;
}

p.avatar-bg.wn {
    background-color: #ffc93c;
}

p.avatar-bg.wo {
    background-color: #38b3c2;
}

p.avatar-bg.wp {
    background-color: #ffc93c;
}

p.avatar-bg.wq {
    background-color: #333333;
}

p.avatar-bg.wr {
    background-color: #38b3c2;
}

p.avatar-bg.ws {
    background-color: #333333;
}

p.avatar-bg.wt {
    background-color: #38b3c2;
}

p.avatar-bg.wu {
    background-color: #31326f;
}

p.avatar-bg.wv {
    background-color: #ad96e5;
}

p.avatar-bg.ww {
    background-color: #38b3c2;
}

p.avatar-bg.wx {
    background-color: #ffc93c;
}

p.avatar-bg.wy {
    background-color: #ad96e5;
}

p.avatar-bg.wz {
    background-color: #31326f;
}

p.avatar-bg.xa {
    background-color: #333333;
}

p.avatar-bg.xb {
    background-color: #31326f;
}

p.avatar-bg.xc {
    background-color: #ad96e5;
}

p.avatar-bg.xd {
    background-color: #38b3c2;
}

p.avatar-bg.xe {
    background-color: #38b3c2;
}

p.avatar-bg.xf {
    background-color: #ffc93c;
}

p.avatar-bg.xg {
    background-color: #31326f;
}

p.avatar-bg.xh {
    background-color: #ffc93c;
}

p.avatar-bg.xi {
    background-color: #31326f;
}

p.avatar-bg.xj {
    background-color: #333333;
}

p.avatar-bg.xk {
    background-color: #38b3c2;
}

p.avatar-bg.xl {
    background-color: #333333;
}

p.avatar-bg.xm {
    background-color: #ffc93c;
}

p.avatar-bg.xn {
    background-color: #ad96e5;
}

p.avatar-bg.xo {
    background-color: #31326f;
}

p.avatar-bg.xp {
    background-color: #38b3c2;
}

p.avatar-bg.xq {
    background-color: #31326f;
}

p.avatar-bg.xr {
    background-color: #ad96e5;
}

p.avatar-bg.xs {
    background-color: #31326f;
}

p.avatar-bg.xt {
    background-color: #ad96e5;
}

p.avatar-bg.xu {
    background-color: #31326f;
}

p.avatar-bg.xv {
    background-color: #38b3c2;
}

p.avatar-bg.xw {
    background-color: #ad96e5;
}

p.avatar-bg.xx {
    background-color: #38b3c2;
}

p.avatar-bg.xy {
    background-color: #31326f;
}

p.avatar-bg.xz {
    background-color: #333333;
}

p.avatar-bg.ya {
    background-color: #38b3c2;
}

p.avatar-bg.yb {
    background-color: #333333;
}

p.avatar-bg.yc {
    background-color: #ad96e5;
}

p.avatar-bg.yd {
    background-color: #ad96e5;
}

p.avatar-bg.ye {
    background-color: #38b3c2;
}

p.avatar-bg.yf {
    background-color: #ad96e5;
}

p.avatar-bg.yg {
    background-color: #333333;
}

p.avatar-bg.yh {
    background-color: #38b3c2;
}

p.avatar-bg.yi {
    background-color: #31326f;
}

p.avatar-bg.yj {
    background-color: #ad96e5;
}

p.avatar-bg.yk {
    background-color: #333333;
}

p.avatar-bg.yl {
    background-color: #31326f;
}

p.avatar-bg.ym {
    background-color: #ffc93c;
}

p.avatar-bg.yn {
    background-color: #ffc93c;
}

p.avatar-bg.yo {
    background-color: #ffc93c;
}

p.avatar-bg.yp {
    background-color: #ad96e5;
}

p.avatar-bg.yq {
    background-color: #ad96e5;
}

p.avatar-bg.yr {
    background-color: #333333;
}

p.avatar-bg.ys {
    background-color: #38b3c2;
}

p.avatar-bg.yt {
    background-color: #31326f;
}

p.avatar-bg.yu {
    background-color: #38b3c2;
}

p.avatar-bg.yv {
    background-color: #31326f;
}

p.avatar-bg.yw {
    background-color: #333333;
}

p.avatar-bg.yx {
    background-color: #31326f;
}

p.avatar-bg.yy {
    background-color: #31326f;
}

p.avatar-bg.yz {
    background-color: #ad96e5;
}

p.avatar-bg.za {
    background-color: #31326f;
}

p.avatar-bg.zb {
    background-color: #ad96e5;
}

p.avatar-bg.zc {
    background-color: #ad96e5;
}

p.avatar-bg.zd {
    background-color: #ad96e5;
}

p.avatar-bg.ze {
    background-color: #ffc93c;
}

p.avatar-bg.zf {
    background-color: #ad96e5;
}

p.avatar-bg.zg {
    background-color: #38b3c2;
}

p.avatar-bg.zh {
    background-color: #31326f;
}

p.avatar-bg.zi {
    background-color: #31326f;
}

p.avatar-bg.zj {
    background-color: #38b3c2;
}

p.avatar-bg.zk {
    background-color: #38b3c2;
}

p.avatar-bg.zl {
    background-color: #38b3c2;
}

p.avatar-bg.zm {
    background-color: #ad96e5;
}

p.avatar-bg.zn {
    background-color: #31326f;
}

p.avatar-bg.zo {
    background-color: #ad96e5;
}

p.avatar-bg.zp {
    background-color: #ad96e5;
}

p.avatar-bg.zq {
    background-color: #31326f;
}

p.avatar-bg.zr {
    background-color: #38b3c2;
}

p.avatar-bg.zs {
    background-color: #31326f;
}

p.avatar-bg.zt {
    background-color: #ad96e5;
}

p.avatar-bg.zu {
    background-color: #38b3c2;
}

p.avatar-bg.zv {
    background-color: #31326f;
}

p.avatar-bg.zw {
    background-color: #38b3c2;
}

p.avatar-bg.zx {
    background-color: #ad96e5;
}

p.avatar-bg.zy {
    background-color: #333333;
}

p.avatar-bg.zz {
    background-color: #ffc93c;
}
