@media (max-width: 1400px) and (min-width: 901px) {
    #team_score,
    .open-psc.without_records,
    .open-gsc,
    .txt-sc.without_records {
    }
    .ctn-lv-hm {
    }
    .ctn-intro {
        //padding: 15px;
        //right: 6%;
        //left: unset;
        //transform: translate(0, -50%);
        width: 55%;
        .intro-text {
            margin-top: 0;
            p {
            }
        }
        .pn-with-rc {
            .btn-go {
                flex-shrink: 0;
                flex-grow: 0;
            }
        }
    }
    .welcome-user {
        left: 6%;
        width: 30%;
        .text-fixed,
        .text-nm {
            font-size: 62px;
        }
    }
    .msg-box {
        top: 250px;
        right: 4%;
        left: 6%;
        .msg-text {
            font-size: 32px;
            u {
                span.underline {
                    height: 1.5vh;
                }
            }
        }
    }
    .ctn-gm-hm {
        .bg-hm {
        }
        .bg-hm.without_records {
        }
    }
}
