.md-form input:not([type]):focus:not([readonly]),
.md-form input[type="text"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="password"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="email"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="url"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="time"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="date"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="datetime"]:not(.browser-default):focus:not([readonly]),
.md-form
    input[type="datetime-local"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="tel"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="number"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="search"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="search-md"]:focus:not([readonly]),
.md-form textarea.md-textarea:focus:not([readonly]) {
    background-size: 100% 100%, 100% 100%;
    box-sizing: border-box;
    border-bottom: 0 solid $m-primary;
    transition: background 0.3s ease-out;
    box-shadow: none;
    outline: 0;
    background-color: rgba(0, 0, 0, 0);
}
.md-form input:not([type]):not([readonly]),
.md-form input[type="text"]:not(.browser-default),
.md-form input[type="password"]:not(.browser-default),
.md-form input[type="email"]:not(.browser-default),
.md-form input[type="url"]:not(.browser-default),
.md-form input[type="time"]:not(.browser-default),
.md-form input[type="date"]:not(.browser-default),
.md-form input[type="datetime"]:not(.browser-default),
.md-form input[type="datetime-local"]:not(.browser-default),
.md-form input[type="tel"]:not(.browser-default),
.md-form input[type="number"]:not(.browser-default),
.md-form input[type="search"]:not(.browser-default),
.md-form input[type="search-md"],
.md-form textarea.md-textarea:not([readonly]) {
    border-bottom: 0px solid $m-dark;
    box-sizing: border-box;
}
.ctn-lg-ct, .row-psw-st, .ctn-rs /* Forget Password Page + Reset Password Page*/ {
    .md-form input:not([type]):not([readonly]),
    .md-form input[type="text"]:not(.browser-default),
    .md-form input[type="password"]:not(.browser-default),
    .md-form input[type="email"]:not(.browser-default),
    .md-form input[type="url"]:not(.browser-default),
    .md-form input[type="time"]:not(.browser-default),
    .md-form input[type="date"]:not(.browser-default),
    .md-form input[type="datetime"]:not(.browser-default),
    .md-form input[type="datetime-local"]:not(.browser-default),
    .md-form input[type="tel"]:not(.browser-default),
    .md-form input[type="number"]:not(.browser-default),
    .md-form input[type="search"]:not(.browser-default),
    .md-form input[type="search-md"],
    .md-form textarea.md-textarea:not([readonly]) {
        border-bottom: 0 solid $m-dark;
        box-sizing: border-box;
    }
}
.md-form input.form-control {
    background: no-repeat center bottom, center calc(100% - 1px);
    background-size: 0 100%, 100% 100%;
    border-bottom: 0px solid $m-dark;
    transition: background 0.1s ease-out;
    box-sizing: border-box;
    background-image: linear-gradient(
            to top,
            $m-primary 2px,
            rgba(156, 39, 176, 0) 2px
        ),
        linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
.md-form input:not([type]):focus:not([readonly]) + label,
.md-form input[type="text"]:not(.browser-default):focus:not([readonly]) + label,
.md-form
    input[type="password"]:not(.browser-default):focus:not([readonly])
    + label,
.md-form
    input[type="email"]:not(.browser-default):focus:not([readonly])
    + label,
.md-form input[type="url"]:not(.browser-default):focus:not([readonly]) + label,
.md-form input[type="time"]:not(.browser-default):focus:not([readonly]) + label,
.md-form input[type="date"]:not(.browser-default):focus:not([readonly]) + label,
.md-form
    input[type="datetime"]:not(.browser-default):focus:not([readonly])
    + label,
.md-form
    input[type="datetime-local"]:not(.browser-default):focus:not([readonly])
    + label,
.md-form input[type="tel"]:not(.browser-default):focus:not([readonly]) + label,
.md-form
    input[type="number"]:not(.browser-default):focus:not([readonly])
    + label,
.md-form
    input[type="search"]:not(.browser-default):focus:not([readonly])
    + label,
.md-form input[type="search-md"]:focus:not([readonly]) + label,
.md-form textarea.md-textarea:focus:not([readonly]) + label {
    color: $m-dark-50;
    font-family: Roboto;
    font-weight: normal;
}
.md-form input:not([type]) + label,
.md-form input[type="text"] + label,
.md-form input[type="password"] + label,
.md-form input[type="email"] + label,
.md-form input[type="url"] + label,
.md-form input[type="time"] + label,
.md-form input[type="date"] + label,
.md-form input[type="datetime"] + label,
.md-form input[type="datetime-local"] + label,
.md-form input[type="tel"] + label,
.md-form input[type="number"] + label,
.md-form input[type="search"] + label,
.md-form input[type="search-md"] + label,
.md-form textarea.md-textarea + label {
    font-family: Roboto;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: 0.15px;
    text-align: left;
    color: $m-dark-50;
    width: 100%;
}
.md-form input:not([type]).valid + label:after,
.md-form input:not([type]):focus.valid + label:after,
.md-form input[type="text"]:not(.browser-default).valid + label:after,
.md-form input[type="text"]:not(.browser-default):focus.valid + label:after,
.md-form input[type="password"]:not(.browser-default).valid + label:after,
.md-form input[type="password"]:not(.browser-default):focus.valid + label:after,
.md-form input[type="email"]:not(.browser-default).valid + label:after,
.md-form input[type="email"]:not(.browser-default):focus.valid + label:after,
.md-form input[type="url"]:not(.browser-default).valid + label:after,
.md-form input[type="url"]:not(.browser-default):focus.valid + label:after,
.md-form input[type="time"]:not(.browser-default).valid + label:after,
.md-form input[type="time"]:not(.browser-default):focus.valid + label:after,
.md-form input[type="date"]:not(.browser-default).valid + label:after,
.md-form input[type="date"]:not(.browser-default):focus.valid + label:after,
.md-form input[type="datetime"]:not(.browser-default).valid + label:after,
.md-form input[type="datetime"]:not(.browser-default):focus.valid + label:after,
.md-form input[type="datetime-local"]:not(.browser-default).valid + label:after,
.md-form
    input[type="datetime-local"]:not(.browser-default):focus.valid
    + label:after,
.md-form input[type="tel"]:not(.browser-default).valid + label:after,
.md-form input[type="tel"]:not(.browser-default):focus.valid + label:after,
.md-form input[type="number"]:not(.browser-default).valid + label:after,
.md-form input[type="number"]:not(.browser-default):focus.valid + label:after,
.md-form input[type="search"]:not(.browser-default).valid + label:after,
.md-form input[type="search"]:not(.browser-default):focus.valid + label:after,
.md-form input[type="search-md"].valid + label:after,
.md-form input[type="search-md"]:focus.valid + label:after,
.md-form textarea.md-textarea.valid + label:after,
.md-form textarea.md-textarea:focus.valid + label:after {
    font-family: Roboto;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: 0.4px;
    text-align: left;
    color: $m-primary;
    top: 4.1rem !important;
    content: attr(data-success);
    opacity: 1;
}
.md-form input:not([type]).invalid + label:after,
.md-form input:not([type]):focus.invalid + label:after,
.md-form input[type="text"]:not(.browser-default).invalid + label:after,
.md-form input[type="text"]:not(.browser-default):focus.invalid + label:after,
.md-form input[type="password"]:not(.browser-default).invalid + label:after,
.md-form
    input[type="password"]:not(.browser-default):focus.invalid
    + label:after,
.md-form input[type="email"]:not(.browser-default).invalid + label:after,
.md-form input[type="email"]:not(.browser-default):focus.invalid + label:after,
.md-form input[type="url"]:not(.browser-default).invalid + label:after,
.md-form input[type="url"]:not(.browser-default):focus.invalid + label:after,
.md-form input[type="time"]:not(.browser-default).invalid + label:after,
.md-form input[type="time"]:not(.browser-default):focus.invalid + label:after,
.md-form input[type="date"]:not(.browser-default).invalid + label:after,
.md-form input[type="date"]:not(.browser-default):focus.invalid + label:after,
.md-form input[type="datetime"]:not(.browser-default).invalid + label:after,
.md-form
    input[type="datetime"]:not(.browser-default):focus.invalid
    + label:after,
.md-form
    input[type="datetime-local"]:not(.browser-default).invalid
    + label:after,
.md-form
    input[type="datetime-local"]:not(.browser-default):focus.invalid
    + label:after,
.md-form input[type="tel"]:not(.browser-default).invalid + label:after,
.md-form input[type="tel"]:not(.browser-default):focus.invalid + label:after,
.md-form input[type="number"]:not(.browser-default).invalid + label:after,
.md-form input[type="number"]:not(.browser-default):focus.invalid + label:after,
.md-form input[type="search"]:not(.browser-default).invalid + label:after,
.md-form input[type="search"]:not(.browser-default):focus.invalid + label:after,
.md-form input[type="search-md"].invalid + label:after,
.md-form input[type="search-md"]:focus.invalid + label:after,
.md-form textarea.md-textarea.invalid + label:after,
.md-form textarea.md-textarea:focus.invalid + label:after {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: 0.4px;
    text-align: left;
    // color: $m-primary;
    top: 4.1rem !important;
    content: attr(data-error);
    opacity: 1;
}
.md-form
    input[type="email"]:not(.browser-default).invalid
    + label.not-found:after,
.md-form
    input[type="email"]:not(.browser-default):focus.invalid
    + label.not-found:after,
.md-form
    input[type="email"]:not(.browser-default).valid
    + label.not-found:after {
    content: attr(data-not-found);
    color: $m-button;
}
.md-form input:not([type]).invalid,
.md-form input:not([type]):focus.invalid,
.md-form input[type="text"]:not(.browser-default).invalid,
.md-form input[type="text"]:not(.browser-default):focus.invalid,
.md-form input[type="password"]:not(.browser-default).invalid,
.md-form input[type="password"]:not(.browser-default):focus.invalid,
.md-form input[type="email"]:not(.browser-default).invalid,
.md-form input[type="email"]:not(.browser-default):focus.invalid,
.md-form input[type="url"]:not(.browser-default).invalid,
.md-form input[type="url"]:not(.browser-default):focus.invalid,
.md-form input[type="time"]:not(.browser-default).invalid,
.md-form input[type="time"]:not(.browser-default):focus.invalid,
.md-form input[type="date"]:not(.browser-default).invalid,
.md-form input[type="date"]:not(.browser-default):focus.invalid,
.md-form input[type="datetime"]:not(.browser-default).invalid,
.md-form input[type="datetime"]:not(.browser-default):focus.invalid,
.md-form input[type="datetime-local"]:not(.browser-default).invalid,
.md-form input[type="datetime-local"]:not(.browser-default):focus.invalid,
.md-form input[type="tel"]:not(.browser-default).invalid,
.md-form input[type="tel"]:not(.browser-default):focus.invalid,
.md-form input[type="number"]:not(.browser-default).invalid,
.md-form input[type="number"]:not(.browser-default):focus.invalid,
.md-form input[type="search"]:not(.browser-default).invalid,
.md-form input[type="search"]:not(.browser-default):focus.invalid,
.md-form input[type="search-md"].invalid,
.md-form input[type="search-md"]:focus.invalid,
.md-form textarea.md-textarea.valid,
.md-form textarea.md-textarea:focus.valid,
.md-form input:not([type]).valid,
.md-form input:not([type]):focus.valid,
.md-form input[type="text"]:not(.browser-default).valid,
.md-form input[type="text"]:not(.browser-default):focus.valid,
.md-form input[type="password"]:not(.browser-default).valid,
.md-form input[type="password"]:not(.browser-default):focus.valid,
.md-form input[type="email"]:not(.browser-default).valid,
.md-form input[type="email"]:not(.browser-default):focus.valid,
.md-form input[type="url"]:not(.browser-default).valid,
.md-form input[type="url"]:not(.browser-default):focus.valid,
.md-form input[type="time"]:not(.browser-default).valid,
.md-form input[type="time"]:not(.browser-default):focus.valid,
.md-form input[type="date"]:not(.browser-default).valid,
.md-form input[type="date"]:not(.browser-default):focus.valid,
.md-form input[type="datetime"]:not(.browser-default).valid,
.md-form input[type="datetime"]:not(.browser-default):focus.valid,
.md-form input[type="datetime-local"]:not(.browser-default).valid,
.md-form input[type="datetime-local"]:not(.browser-default):focus.valid,
.md-form input[type="tel"]:not(.browser-default).valid,
.md-form input[type="tel"]:not(.browser-default):focus.valid,
.md-form input[type="number"]:not(.browser-default).valid,
.md-form input[type="number"]:not(.browser-default):focus.valid,
.md-form input[type="search"]:not(.browser-default).valid,
.md-form input[type="search"]:not(.browser-default):focus.valid,
.md-form input[type="search-md"].valid,
.md-form input[type="search-md"]:focus.valid,
.md-form textarea.md-textarea.valid,
.md-form textarea.md-textarea:focus.valid {
    background-size: 100% 100%, 100% 100%;
    border-bottom: 0 solid $m-primary;
    box-shadow: none;
    outline: 0;
    background-color: rgba(0, 0, 0, 0);
    //border-color:$m-primary;box-shadow:0 1px 0 0 $m-primary;
}

input[type="text"].form-control.valid + label:after,
input[type="text"].form-control:focus.valid + label:after,
input[type="text"].form-control.invalid + label:after,
input[type="text"].form-control:focus.invalid + label:after,
input[type="password"].form-control.valid + label:after,
input[type="password"].form-control:focus.valid + label:after,
input[type="password"].form-control.invalid + label:after,
input[type="password"].form-control:focus.invalid + label:after {
    color: $m-primary;
}
