//colors
$m-light: #f5f5f5;
$m-headers: #ad96e5;
$m-headers-50: rgba(173, 119, 229, 0.5);
$m-errors: #f57768;
$m-errors-75: rgba(245, 119, 104, 0.75);
$m-errors-25: rgba(245, 119, 104, 0.25);
$m-bonus: #ffc93c;
$m-secondary: #fff7cb;
$m-primary: #31326f;
$m-primary-80: rgba(49, 50, 111, 0.8);
$m-primary-50: rgba(49, 50, 111, 0.5);
$m-primary-25: rgba(49, 50, 111, 0.25);
$m-button: #38b3c2;
$m-button-50: rgba(56, 179, 194, 0.5);
$m-helper: #dbf6e9;
$m-dark: #333333;
$m-dark-75: rgba(51, 51, 51, 0.75);
$m-dark-50: rgba(51, 51, 51, 0.5);
$m-dark-25: rgba(51, 51, 51, 0.25);
$white: #ffffff;
$white-25: rgba(255, 255, 255, 0.25);
$white-50: rgba(255, 255, 255, 0.5);
$black: #000000;
$blur-bg: rgba(179, 179, 179, 0.1);
$transparent: rgba(255, 255, 255, 0);
$greyish: #aeaeae;
$m-helper-1: #387da4;

//LIST OF COLORS
$colors-nv: (
    "m-light": #f5f5f5,
    "m-headers": #ad96e5,
    "m-headers-50": rgba(173, 119, 229, 0.5),
    "m-errors": #f57768,
    "m-errors-75": rgba(245, 119, 104, 0.75),
    "m-errors-25": rgba(245, 119, 104, 0.25),
    "m-bonus": #ffc93c,
    "m-secondary": #fff7cb,
    "m-primary": #31326f,
    "m-primary-80": rgba(49, 50, 111, 0.8),
    "m-primary-50": rgba(49, 50, 111, 0.5),
    "m-primary-25": rgba(49, 50, 111, 0.25),
    "m-button": #38b3c2,
    "m-button-50": rgba(56, 179, 194, 0.5),
    "m-helper": #dbf6e9,
    "m-dark": #333333,
    "m-dark-75": rgba(51, 51, 51, 0.75),
    "m-dark-50": rgba(51, 51, 51, 0.5),
    "m-dark-25": rgba(51, 51, 51, 0.25),
    "white": #ffffff,
    "white-25": rgba(255, 255, 255, 0.25),
    "white-50": rgba(255, 255, 255, 0.5),
    "black": #000000,
    "blur-bg": rgba(179, 179, 179, 0.1),
    "transparent": rgba(255, 255, 255, 0),
    "greyish": #aeaeae,
    "m-helper-1": #387da4
);
  
// Typography
$font-family-sans-serif: Roboto, sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

//height fixed
$nav-heigth-fixed: 56px;
$mask-curve-heigth-fixed: 54px;

///Bg
$colors-nv-1: #31326f, #38b3c2, #ffc93c, #ad96e5, #333333;
$avatar-colors-nv-1: #31326f, #38b3c2, #ffc93c, #ad96e5, #333333;
$alphabet-nv: "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m",
    "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z";
